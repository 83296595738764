import React, { useState, useRef } from 'react';
import { Input, Tag } from 'antd';

const { TextArea } = Input;

const QuickNotesSelector = ({ onNoteChange, value = '' }) => {
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [combinedText, setCombinedText] = useState(value);
  const inputRef = useRef(null);

  // Full set of quick notes for autocomplete
  const allQuickNotesData = [
    {
      title: "Lead Status",
      items: [
        { id: 1, text: 'Not interested' },
        { id: 2, text: 'Not picked' },
        { id: 3, text: 'Budget issues' },
        { id: 4, text: 'Not interested after Demo' },
        { id: 5, text: 'Will pay after' },
        { id: 6, text: 'Wants discount' },
        { id: 17, text: 'Call disconnected' },
        { id: 18, text: 'Disconnected after listening NIL' },
        { id: 34, text: 'Not on WhatsApp' },
      ]
    },
    {
      title: "Communication",
      items: [
        { id: 7, text: 'is busy asked to call after/later' },
        { id: 8, text: 'Connected on WhatsApp' },
        { id: 9, text: 'Details shared' },
        { id: 19, text: 'Said someone else enquired' },
        { id: 20, text: 'Wants to start next month' },
        { id: 21, text: 'Busy in office, Call later' },
        { id: 22, text: 'Busy in function, Call later' },
        { id: 23, text: 'Wants free classes' },
        { id: 24, text: 'Switched off/unreachable' },
        { id: 26, text: 'Hindi speaker' },
        { id: 27, text: 'Cannot make sentences' },
        { id: 28, text: 'Casual, not serious' },
        { id: 29, text: 'Will connect after:' },
        { id: 30, text: 'Working Professional' },
        { id: 31, text: 'Basic Speaker/Average Speaker' },
        { id: 32, text: 'Student/Studying' },
        { id: 33, text: 'Invalid Number/Switched off/No Incoming' },
       
      ]
    },
    {
      title: "Course Types",
      items: [
        { id: 10, text: 'Foundation PT' },
        { id: 11, text: 'Foundation group' },
        { id: 12, text: 'Advanced PT' },
        { id: 13, text: 'Advanced Group' },
        { id: 14, text: 'Business PT' },
        { id: 15, text: 'One month' },
        { id: 16, text: 'Three month' },
        { id: 25, text: 'Kids Communication classes' }
      ]
    }
  ];

  // Filtered quick notes for display
  const displayQuickNotes = allQuickNotesData.map(category => ({
    title: category.title,
    items: category.items.filter(item => [1, 2, 3, 4, 8, 9, 17, 18,34].includes(item.id))
  })).filter(category => category.items.length > 0);

  // All quick notes for autocomplete
  const allQuickNotes = allQuickNotesData.flatMap(category => 
    category.items.map(item => ({
      ...item,
      category: category.title
    }))
  );

  const findSuggestions = (text, position) => {
    const textBeforeCursor = text.slice(0, position);
    const words = textBeforeCursor.split(/[;,.\s]+/);
    const currentWord = words[words.length - 1].toLowerCase();

    if (currentWord.length < 2) {
      setSuggestions([]);
      return;
    }

    const matches = allQuickNotes.filter(note =>
      note.text.toLowerCase().includes(currentWord)
    );

    setSuggestions(matches.slice(0, 5));
    setActiveSuggestion(0);
  };

  const handleNoteClick = (noteText) => {
    let updatedNotes;
    if (selectedNotes.includes(noteText)) {
      updatedNotes = selectedNotes.filter(note => note !== noteText);
    } else {
      updatedNotes = [...selectedNotes, noteText];
    }
    setSelectedNotes(updatedNotes);

    const newText = combinedText ? 
      (combinedText.includes(noteText) ? 
        combinedText.split('; ').filter(part => part !== noteText).join('; ') :
        `${combinedText}; ${noteText}`
      ) : noteText;
    
    setCombinedText(newText);
    onNoteChange(newText);
  };

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    const newPosition = e.target.selectionStart || 0;
    
    setCombinedText(newValue);
    setCursorPosition(newPosition);
    onNoteChange(newValue);
    findSuggestions(newValue, newPosition);
  };

  const applySuggestion = (suggestionText) => {
    const textBeforeCursor = combinedText.slice(0, cursorPosition);
    const textAfterCursor = combinedText.slice(cursorPosition);
    const words = textBeforeCursor.split(/([;,.\s]+)/);
    
    words[words.length - 1] = suggestionText;
    
    const newText = words.join('') + textAfterCursor;
    setCombinedText(newText);
    onNoteChange(newText);
    setSuggestions([]);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (suggestions.length === 0) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveSuggestion(prev => 
        prev < suggestions.length - 1 ? prev + 1 : 0
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveSuggestion(prev => 
        prev > 0 ? prev - 1 : suggestions.length - 1
      );
    } else if (e.key === 'Enter' && suggestions.length > 0) {
      e.preventDefault();
      applySuggestion(suggestions[activeSuggestion].text);
    } else if (e.key === 'Escape') {
      setSuggestions([]);
    }
  };

  return (
    <div className="space-y-4">
      <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
        <div className="grid grid-cols-2 gap-4">
          {displayQuickNotes.map((column, index) => (
            <div key={index} className="flex flex-col">
              <h3 className="text-sm font-medium text-gray-600 mb-2 sticky top-0 bg-gray-50 py-1 z-10 border-b">
                {column.title}
              </h3>
              <div className="overflow-y-auto max-h-24 pr-1">
                <div className="space-y-1">
                  {column.items.map((note) => (
                    <div 
                      key={note.id}
                      className={`cursor-pointer rounded-lg text-sm transition-all p-2 break-words ${
                        selectedNotes.includes(note.text)
                          ? 'bg-blue-500 text-white hover:bg-blue-600'
                          : 'bg-white hover:bg-gray-100'
                      }`}
                      onClick={() => handleNoteClick(note.text)}
                    >
                      {note.text}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="relative">
        <TextArea
          ref={inputRef}
          value={combinedText}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          placeholder="Type to search quick notes or enter custom text..."
          className="w-full p-3 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          autoSize={{ minRows: 2, maxRows: 6 }}
        />
        
        {suggestions.length > 0 && (
          <div className="absolute left-0 right-0 mt-1 bg-white border rounded-lg shadow-lg z-50 max-h-48 overflow-y-auto">
            {suggestions.map((suggestion, index) => (
              <div
                key={suggestion.id}
                className={`p-2 cursor-pointer ${
                  index === activeSuggestion 
                    ? 'bg-blue-50 border-l-4 border-blue-500' 
                    : 'hover:bg-gray-50'
                }`}
                onClick={() => applySuggestion(suggestion.text)}
              >
                <div className="text-sm font-medium">{suggestion.text}</div>
                <div className="text-xs text-gray-500">{suggestion.category}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-2">
        {selectedNotes.map((note) => (
          <Tag
            key={note}
            closable
            onClose={() => handleNoteClick(note)}
            className="bg-blue-50 border-blue-200 text-blue-800"
          >
            {note}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default QuickNotesSelector;