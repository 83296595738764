import React, { useState, useEffect } from 'react';
import { Modal, Typography, Spin, Button } from 'antd';
import styled, { keyframes } from 'styled-components';

const { Text } = Typography;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlowWrapper = styled.div`
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(45deg, purple, yellow, red, blue);
    background-size: 400% 400%;
    z-index: -1;
    filter: blur(5px);
    animation: ${glowAnimation} 15s ease infinite;
    border-radius: 15px;
    opacity: 0.7;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    color: #ffffff;
    border-radius: 15px;
  }
  .ant-modal-header {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .ant-modal-title {
    color: #ffffff;
  }
  .ant-modal-close-x {
    color: #ffffff;
  }
  .ant-modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const QuoteBlockquote = styled.blockquote`
  font-size: 1.2em;
  margin: 20px 0;
  padding: 10px 20px;
  border-left: 5px solid #1890ff;
  font-style: italic;
  color: #ffffff;
`;

const AuthorText = styled(Text)`
  display: block;
  text-align: right;
  color: #cccccc;
  font-style: italic;
  margin-top: 10px;
`;

// Complete list of 100 quotes from the PDF
const quotes = [
  { content: "Act as if what you do makes a difference. It does.", author: "William James" },
  { content: "I don't need it to be easy, I need it to be worth it.", author: "Lil Wayne" },
  { content: "You do not find the happy life. You make it.", author: "Camilla Eyring Kimball" },
  { content: "Never regret anything that made you smile.", author: "Mark Twain" },
  { content: "Some people look for a beautiful place. Others make a place beautiful.", author: "Hazrat Inayat Khan" },
  { content: "A champion is defined not by their wins but by how they can recover when they fall.", author: "Serena Williams" },
  { content: "If I cannot do great things, I can do small things in a great way.", author: "Martin Luther King Jr." },
  { content: "The bad news is time flies. The good news is you're the pilot.", author: "Michael Altshuler" },
  { content: "They never die, who have the future in them.", author: "Meridel Le Sueur" },
  { content: "Where the willingness is great, the difficulties cannot be great.", author: "Niccolo Machiavelli" },
  { content: "Never let go of that fiery sadness called desire.", author: "Patti Smith" },
  { content: "Never bend your head. Always hold it high. Look the world straight in the eye.", author: "Helen Keller" },
  { content: "Winning isn't everything. Wanting to win is.", author: "Catfish Hunter" },
  { content: "He that can have patience can have what he will.", author: "Benjamin Franklin" },
  { content: "The only real mistake is the one from which we learn nothing.", author: "Henry Ford" },
  { content: "Don't quit. Suffer now and live the rest of your life as a champion.", author: "Muhammad Ali" },
  { content: "If you look at what you have in life, you'll always have more.", author: "Oprah Winfrey" },
  { content: "Turn your wounds into wisdom.", author: "Oprah Winfrey" },
  { content: "Everything you've ever wanted is on the other side of fear.", author: "George Addair" },
  { content: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis" },
  { content: "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.", author: "Jimmy Dean" },
  { content: "My mission in life is not merely to survive, but to thrive.", author: "Maya Angelou" },
  { content: "Success is not final, failure is not fatal: it is the courage to continue that counts.", author: "Winston Churchill" },
  { content: "Fear is a reaction. Courage is a decision.", author: "Winston Churchill" },
  { content: "Inspiration comes from within yourself. One has to be positive. When you're positive, good things happen.", author: "Deep Roy" },
  { content: "Happiness is not by chance, but by choice.", author: "Jim Rohn" },
  { content: "Either you run the day, or the day runs you.", author: "Jim Rohn" },
  { content: "Dream as if you'll live forever, live as if you'll die today.", author: "James Dean" },
  { content: "Be yourself; everyone else is already taken.", author: "Oscar Wilde" },
  { content: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
  { content: "You must do the things you think you cannot do.", author: "Eleanor Roosevelt" },
  { content: "No one can make you feel inferior without your consent.", author: "Eleanor Roosevelt" },
  { content: "Change the world by being yourself.", author: "Amy Poehler" },
  { content: "Mastering others is strength. Mastering yourself is true power.", author: "Lao Tzu" },
  { content: "The journey of a thousand miles begins with one step.", author: "Lao Tzu" },
  { content: "Simplicity is the ultimate sophistication.", author: "Leonardo da Vinci" },
  { content: "The roots of education are bitter, but the fruit is sweet.", author: "Aristotle" },
  { content: "It's not whether you get knocked down; it's whether you get up.", author: "Vince Lombardi" },
  { content: "In great attempts, it is glorious even to fail.", author: "Vince Lombardi" },
  { content: "You don't always need a plan. Sometimes you just need to breathe, trust, let go, and see what happens.", author: "Mandy Hale" },
  { content: "It is never too late to be what you might have been.", author: "George Eliot" },
  { content: "Be not afraid of going slowly, be afraid only of standing still.", author: "Chinese Proverb" },
  { content: "Fall seven times, stand up eight.", author: "Japanese Proverb" },
  { content: "It is better to live one day as a lion, than a thousand days as a lamb.", author: "Roman Proverb" },
  { content: "Sometimes your joy is the source of your smile, but sometimes your smile can be the source of your joy.", author: "Thich Nhat Hanh" },
  { content: "Happy people plan actions, they don't plan results.", author: "Dennis Waitley" },
  { content: "Life is like riding a bicycle. To keep your balance, you must keep moving.", author: "Albert Einstein" },
  { content: "In the middle of difficulty lies opportunity.", author: "Albert Einstein" },
  { content: "To dare is to lose one's footing momentarily. To not dare is to lose oneself.", author: "Soren Kierkegaard" },
  { content: "Each person must live their life as a model for others.", author: "Rosa Parks" },
  { content: "All limitations are self-imposed.", author: "Oliver Wendell Holmes" },
  { content: "Motivation comes from working on things we care about.", author: "Sheryl Sandberg" },
  { content: "In life you need either inspiration or desperation.", author: "Tony Robbins" },
  { content: "The only thing that can grow is the thing you give energy to.", author: "Ralph Waldo Emerson" },
  { content: "Being miserable is a habit; being happy is a habit; and the choice is yours.", author: "Tom Hopkins" },
  { content: "Die with memories, not dreams.", author: "Anonymous" },
  { content: "It's going to be hard, but hard does not mean impossible.", author: "Anonymous" },
  { content: "Sometimes we're tested not to show our weaknesses, but to discover our strengths.", author: "Anonymous" },
  { content: "The harder you work for something, the greater you'll feel when you achieve it.", author: "Anonymous" },
  { content: "Your limitation — it's only your imagination.", author: "Anonymous" },
  { content: "Don't stop when you're tired. Stop when you're done.", author: "Anonymous" },
  { content: "Push yourself, because no one else is going to do it for you.", author: "Anonymous" },
  { content: "Happiness is not something ready-made. It comes from your own actions.", author: "Dalai Lama" },
  { content: "There is no saint without a past, no sinner without a future.", author: "Augustine of Hippo" },
  { content: "Normality is a paved road: it's comfortable to walk but no flowers grow.", author: "Vincent van Gogh" },
  { content: "Great things never come from comfort zones.", author: "Anonymous" },
  { content: "Do something today that your future self will thank you for.", author: "Anonymous" },
  { content: "Good things happen to those who hustle.", author: "Anais Nin" },
  { content: "Wanting to be someone else is a waste of who you are.", author: "Kurt Cobain" },
  { content: "If you love life, don't waste time, for time is what life is made up of.", author: "Bruce Lee" },
  { content: "Life changes very quickly, in a very positive way, if you let it.", author: "Lindsey Vonn" },
  { content: "Courage is never to let your actions be influenced by your fears.", author: "Arthur Koestler" },
  { content: "When you have a dream, you've got to grab it and never let go.", author: "Carol Burnett" },
  { content: "Sometimes you will never know the value of a moment, until it becomes a memory.", author: "Dr. Seuss" },
  { content: "Don't cry because it's over. Smile because it happened.", author: "Dr. Seuss" },
  { content: "Most people would rather be certain they're miserable, than risk being happy.", author: "Dr. Robert Anthony" },
  { content: "May your choices reflect your hopes, not your fears.", author: "Nelson Mandela" },
  { content: "Try to be a rainbow in someone else's cloud.", author: "Maya Angelou" },
  { content: "Life is really simple, but we insist on making it complicated.", author: "Confucius" },
  { content: "Where there is love and inspiration, I don't think you can go wrong.", author: "Ella Fitzgerald" },
  { content: "It isn't where you came from. It's where you're going that counts.", author: "Ella Fitzgerald" },
  { content: "What you get by achieving your goals is not as important as what you become by achieving your goals.", author: "Zig Ziglar" },
  { content: "The meaning of life is to give life meaning.", author: "Ken Hudgins" },
  { content: "No matter what you're going through, there's a light at the end of the tunnel.", author: "Demi Lovato" },
  { content: "Smooth seas do not make skillful sailors.", author: "Proverb" },
  { content: "Energy and initiative count as much as talent and luck.", author: "Will Peters" },
  { content: "Be so good they can't ignore you.", author: "Steve Martin" },
  { content: "Every noble work is at first impossible.", author: "Thomas Carlyle" },
  { content: "Let us make our future now, and let us make our dreams tomorrow's reality.", author: "Malala Yousafzai" },
  { content: "Stay close to anything that makes you glad you are alive.", author: "Hafez" },
  { content: "Life without love is like a tree without blossoms or fruit.", author: "Khalil Gibran" },
  { content: "I am an artist at living, my work of art is my life.", author: "D.T. Suzuki" },
  { content: "The unhappy derive comfort from the misfortunes of others.", author: "Aesop" },
  { content: "Nothing is impossible. The word itself says -I possible!", author: "Audrey Hepburn" },
  { content: "Never limit yourself because of others' limited imagination; never limit others because of your own limited imagination.", author: "Mae Jemison" },
  { content: "Don't wait. The time will never be just right.", author: "Napoleon Hill" },
  { content: "Forget about style; worry about results.", author: "Bobby Orr" },
  { content: "Tough times never last but tough people do.", author: "Robert H. Schiuller" },
  { content: "Goals help you channel your energy into action.", author: "Les Brown" },
  { content: "Other people's opinion of you does not have to become your reality.", author: "Les Brown" }
];

const QuotePopup = ({ visible, onClose }) => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canClose, setCanClose] = useState(false);

  useEffect(() => {
    if (visible) {
      getRandomQuote();
      setCanClose(false);
      const timer = setTimeout(() => {
        setCanClose(true);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  const getRandomQuote = () => {
    setLoading(true);
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
    setLoading(false);
  };

  return (
    <GlowWrapper>
      <StyledModal
        visible={visible}
        onOk={onClose}
        onCancel={onClose}
        title="Let's Get A Little Motivation From Wise People"
        footer={[
          
        ]}
        closable={true}
        maskClosable={false}
      >
        {loading ? (
          <Spin size="large" />
        ) : quote ? (
          <>
            <QuoteBlockquote>{quote.content}</QuoteBlockquote>
            <AuthorText>— {quote.author}</AuthorText>
          </>
        ) : (
          <Text>Failed to load quote. Please try again later.</Text>
        )}
      </StyledModal>
    </GlowWrapper>
  );
};

export default QuotePopup;