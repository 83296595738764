import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Badge, Dropdown, List, Modal, Button, Form, Input, Select, DatePicker, message, Tooltip, Avatar, Popover, Divider } from 'antd';
import { BellOutlined, ClockCircleOutlined, UserOutlined, FileTextOutlined } from '@ant-design/icons';
import { getNotifications, markNotificationAsRead, updateReminder, getReminder, updateTask, getTaskById, getAllUsers, getComments, addComment } from '../apiService';
import moment from 'moment-timezone';
import './Notifications.css';

const { Option } = Select;
const { TextArea } = Input;

const formatDateTimeForAPI = (dateTime) => {
  return moment(dateTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
};

const formatDateTimeForDisplay = (dateTime) => {
  return moment(dateTime).format('D-MMM-YYYY, hh:mm A');
};

const parseDateTimeFromAPI = (dateTime) => {
  return moment(dateTime);
};

const NotificationOverlay = ({ children }) => (
  <div style={{
    background: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    padding: '8px 0',
    minWidth: '350px',
    maxWidth: '450px',
    width: '100%',
  }}>
    {children}
  </div>
);

NotificationOverlay.propTypes = {
  children: PropTypes.node.isRequired,
};

const Notifications = ({ fullyAuthenticated }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [reminderModalVisible, setReminderModalVisible] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [form] = Form.useForm();
  const [taskForm] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadAssignmentSummary, setLeadAssignmentSummary] = useState(null);
  const [leadStatusSummary, setLeadStatusSummary] = useState(null);
  const [commentInputs, setCommentInputs] = useState({});
  const [loadingMoreComments, setLoadingMoreComments] = useState({});

  const fetchNotifications = useCallback(async () => {
    if (!fullyAuthenticated) return;

    setLoading(true);
    try {
      const response = await getNotifications();
      const groupedNotifications = groupNotifications(response);
      setNotifications(groupedNotifications);
      const unreadNotifications = groupedNotifications.filter(notification => notification.is_read === "0");
      setUnreadCount(unreadNotifications.length);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      message.error('Failed to load notifications');
    } finally {
      setLoading(false);
    }
  }, [fullyAuthenticated]);

  useEffect(() => {
    if (fullyAuthenticated) {
      fetchNotifications();
      fetchUsers();
      const interval = setInterval(fetchNotifications, 60000); // Refresh every minute
      return () => clearInterval(interval);
    }
  }, [fetchNotifications, fullyAuthenticated]);

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const groupNotifications = (notificationsArray) => {
    const grouped = notificationsArray.reduce((acc, notification) => {
      if (notification.type === 'lead_reassigned') {
        if (!acc.leadAssignments) {
          acc.leadAssignments = { count: 0, notifications: [], type: 'lead_assignments_summary', is_read: "0" };
        }
        acc.leadAssignments.count++;
        acc.leadAssignments.notifications.push(notification);
      } else if (notification.type === 'lead_status_changed') {
        if (!acc.leadStatusUpdates) {
          acc.leadStatusUpdates = { count: 0, notifications: [], type: 'lead_status_summary', is_read: "0" };
        }
        acc.leadStatusUpdates.count++;
        acc.leadStatusUpdates.notifications.push(notification);
      } else {
        acc.other.push(notification);
      }
      return acc;
    }, { other: [] });

    setLeadAssignmentSummary(grouped.leadAssignments || null);
    setLeadStatusSummary(grouped.leadStatusUpdates || null);

    return [
      ...grouped.other,
      ...(grouped.leadAssignments ? [grouped.leadAssignments] : []),
      ...(grouped.leadStatusUpdates ? [grouped.leadStatusUpdates] : [])
    ];
  };

  const handleNotificationClick = async (notification) => {
    try {
      if (notification.notifications) {
        // Handling grouped notifications
        if (notification.type === 'lead_assignments_summary') {
          await Promise.all(notification.notifications.map(n => markNotificationAsRead(n.id)));
          setLeadAssignmentSummary(null);
          message.success(`Marked ${notification.count} lead assignment notifications as read`);
        } else if (notification.type === 'lead_status_summary') {
          await Promise.all(notification.notifications.map(n => markNotificationAsRead(n.id)));
          setLeadStatusSummary(null);
          message.success(`Marked ${notification.count} lead status update notifications as read`);
        }
      } else {
        // Handling individual notifications
        if (notification.type === 'reminder') {
          const reminderDetails = await getReminder(notification.reference_id);
          setSelectedReminder(reminderDetails);
          form.setFieldsValue({
            ...reminderDetails,
            datetime: parseDateTimeFromAPI(reminderDetails.datetime)
          });
          setReminderModalVisible(true);
        } else if (notification.type.includes('task')) {
          const taskDetails = await getTaskById(notification.reference_id);
          setSelectedTask(taskDetails);
          taskForm.setFieldsValue({
            ...taskDetails,
            due_date: parseDateTimeFromAPI(taskDetails.due_date),
          });
          setTaskModalVisible(true);
        }
        await markNotificationAsRead(notification.id);
      }
      
      setNotifications(prevNotifications =>
        prevNotifications.map(n =>
          n.id === notification.id ? { ...n, is_read: "1" } : n
        )
      );
      setUnreadCount(prevCount => Math.max(0, prevCount - 1));
    } catch (error) {
      console.error('Error handling notification click:', error);
      message.error('Failed to process notification');
    }
  };

  const handleReminderUpdate = async (values) => {
    try {
      const reminderData = {
        ...values,
        datetime: formatDateTimeForAPI(values.datetime)
      };
      await updateReminder(selectedReminder.id, reminderData);
      message.success('Reminder updated successfully');
      setReminderModalVisible(false);
      fetchNotifications();
    } catch (error) {
      console.error('Error updating reminder:', error);
      message.error('Failed to update reminder');
    }
  };

  const handleTaskUpdate = async (values) => {
    try {
      const taskData = {
        ...values,
        due_date: values.due_date ? formatDateTimeForAPI(values.due_date) : null,
      };
      await updateTask(selectedTask.id, taskData);
      message.success('Task updated successfully');
      setTaskModalVisible(false);
      fetchNotifications();
    } catch (error) {
      console.error('Error updating task:', error);
      message.error('Failed to update task');
    }
  };

  const renderNotificationIcon = (type) => {
    switch (type) {
      case 'reminder':
        return <ClockCircleOutlined style={{ color: '#faad14' }} />;
      case 'task_due':
      case 'task_assigned':
      case 'task_status_update':
        return <FileTextOutlined style={{ color: '#1890ff' }} />;
      case 'lead_assigned':
      case 'lead_status_changed':
      case 'lead_assignments_summary':
      case 'lead_status_summary':
        return <UserOutlined style={{ color: '#52c41a' }} />;
      default:
        return <BellOutlined style={{ color: '#8c8c8c' }} />;
    }
  };

  const renderNotificationMessage = (notification) => {
    if (notification.notifications) {
      // This is a grouped notification
      return `${notification.count} ${notification.type === 'lead_assignments_summary' ? 'new leads assigned' : 'lead status updates'}`;
    } else {
      // This is an individual notification
      return notification.message || 'New Notification';
    }
  };

  const renderNotificationContent = (notification) => (
    <List.Item 
      className="notification-item" 
      onClick={() => handleNotificationClick(notification)}
    >
      <List.Item.Meta
        avatar={<Avatar icon={renderNotificationIcon(notification.type)} />}
        title={renderNotificationMessage(notification)}
        description={formatDateTimeForDisplay(notification.created_at)}
      />
    </List.Item>
  );

  const handleMarkAllAsRead = async () => {
    try {
      const unreadNotifications = notifications.filter(n => n.is_read === "0");
      for (const notification of unreadNotifications) {
        if (notification.notifications) {
          // For grouped notifications
          await Promise.all(notification.notifications.map(subN => markNotificationAsRead(subN.id)));
        } else {
          // For individual notifications
          await markNotificationAsRead(notification.id);
        }
      }
      setNotifications(prevNotifications =>
        prevNotifications.map(n => ({ ...n, is_read: "1" }))
      );
      setUnreadCount(0);
      setLeadAssignmentSummary(null);
      setLeadStatusSummary(null);
      message.success('All notifications marked as read');
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      message.error('Failed to mark all notifications as read');
    }
  };

  const handleAddComment = async (taskId) => {
    const commentContent = commentInputs[taskId];
    if (!commentContent || !commentContent.trim()) {
      message.error('Comment cannot be empty');
      return;
    }
    try {
      await addComment(taskId, { content: commentContent });
      message.success('Comment added successfully');
      setCommentInputs(prev => ({ ...prev, [taskId]: '' }));
      const updatedComments = await getComments(taskId);
      setSelectedTask(prev => ({ ...prev, comments: updatedComments }));
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error('Failed to add comment');
    }
  };

  const loadMoreComments = async (taskId) => {
    setLoadingMoreComments(prev => ({ ...prev, [taskId]: true }));
    try {
      const currentComments = selectedTask.comments;
      const newComments = await getComments(taskId, Math.ceil(currentComments.length / 5) + 1, 5);
      setSelectedTask(prev => ({ ...prev, comments: [...prev.comments, ...newComments] }));
    } catch (error) {
      console.error('Error loading more comments:', error);
      message.error('Failed to load more comments');
    } finally {
      setLoadingMoreComments(prev => ({ ...prev, [taskId]: false }));
    }
  };

  const notificationList = (
    <NotificationOverlay>
      <div style={{ padding: '8px 16px', borderBottom: '1px solid #f0f0f0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Notifications</span>
        <Button size="small" onClick={handleMarkAllAsRead}>Mark all as read</Button>
      </div>
      <List
        className="notification-list"
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={renderNotificationContent}
        locale={{ emptyText: 'No notifications' }}
        loading={loading}
      />
    </NotificationOverlay>
  );

  return (
    <>
      <Dropdown overlay={notificationList} trigger={['click']} placement="bottomRight">
        <Badge count={unreadCount} className="notification-badge">
          <Tooltip title="Notifications">
            <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
          </Tooltip>
        </Badge>
      </Dropdown>

      <Modal
        title="Reminder Details"
        visible={reminderModalVisible}
        onCancel={() => setReminderModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleReminderUpdate} layout="vertical">
          <Form.Item name="id" hidden><Input /></Form.Item>
          <Form.Item name="lead_id" label="Lead ID">
            <Input disabled />
          </Form.Item>
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select>
              <Option value="booking_date">Booking Date</Option>
              <Option value="next_call_date">Next Call Date</Option>
              <Option value="meeting">Meeting</Option>
            </Select>
          </Form.Item>
          <Form.Item name="datetime" label="Date and Time" rules={[{ required: true }]}>
            <DatePicker 
              showTime={{ format: 'hh:mm A' }}
              format="D-MMM-YYYY, hh:mm A"
              placeholder="Select date and time"
            />
          </Form.Item>
          <Form.Item name="message" label="Message" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="pending">Pending</Option>
              <Option value="completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="assigned_to" label="Assigned To" rules={[{ required: true }]}>
            <Select>
            {users.map(user => (
                <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Reminder</Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Task Details"
        visible={taskModalVisible}
        onCancel={() => setTaskModalVisible(false)}
        footer={null}
      >
        <Form form={taskForm} onFinish={handleTaskUpdate} layout="vertical">
          <Form.Item name="id" hidden><Input /></Form.Item>
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="due_date" label="Due Date" rules={[{ required: true }]}>
            <DatePicker 
              showTime={{ format: 'hh:mm A' }}
              format="D-MMM-YYYY, hh:mm A"
              placeholder="Select due date and time"
            />
          </Form.Item>
          <Form.Item name="assigned_to" label="Assigned To" rules={[{ required: true }]}>
            <Select>
              {users.map(user => (
                <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select>
              <Option value="pending">Pending</Option>
              <Option value="in_progress">In Progress</Option>
              <Option value="completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Task</Button>
          </Form.Item>
        </Form>
        <Divider />
        <div>
          <h4>Comments</h4>
          <List
            dataSource={selectedTask?.comments || []}
            renderItem={comment => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={comment.user_avatar} icon={<UserOutlined />} />}
                  title={comment.user_name}
                  description={
                    <>
                      <p>{comment.content}</p>
                      <small>{formatDateTimeForDisplay(comment.created_at)}</small>
                    </>
                  }
                />
              </List.Item>
            )}
          />
          {selectedTask?.comments_count > (selectedTask?.comments?.length || 0) && (
            <Button 
              onClick={() => loadMoreComments(selectedTask.id)} 
              loading={loadingMoreComments[selectedTask.id]}
            >
              Load More Comments
            </Button>
          )}
          <div style={{ marginTop: '16px' }}>
            <TextArea
              rows={2}
              value={commentInputs[selectedTask?.id] || ''}
              onChange={(e) => setCommentInputs(prev => ({ ...prev, [selectedTask.id]: e.target.value }))}
              placeholder="Write a comment..."
            />
            <Button
              type="primary"
              onClick={() => handleAddComment(selectedTask.id)}
              style={{ marginTop: '8px' }}
            >
              Add Comment
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

Notifications.propTypes = {
  fullyAuthenticated: PropTypes.bool.isRequired,
};

export default Notifications;