import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, List, Image, Input, Button, message, Upload, Popconfirm, Tag, Typography, Row, Col, Tooltip, Avatar, Modal,Progress } from 'antd';
import { LikeOutlined, CommentOutlined, PushpinOutlined, UploadOutlined, SendOutlined, DownloadOutlined, FileOutlined, FilePdfOutlined, FileWordOutlined, FileExcelOutlined, DeleteOutlined } from '@ant-design/icons';
import { getSocialUpdates, createSocialUpdate, likeSocialUpdate, unlikeSocialUpdate, pinSocialUpdate, unpinSocialUpdate, getComments, addComment, getAllUsers, deleteSocialUpdate, getUserDetailsById } from '../apiService';
import ReactMarkdown from 'react-markdown';
import './SocialUpdates.css';
import { formatDateTimeForDisplay } from '../utils/dateUtils';

const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;

const SocialUpdates = ({ currentUser }) => {
  const [updates, setUpdates] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newUpdate, setNewUpdate] = useState('');
  const [fileList, setFileList] = useState([]);
  const [commentInputs, setCommentInputs] = useState({});
  const [loadingMoreComments, setLoadingMoreComments] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const isAdmin = currentUser.role === 'administrator';
  const [loadedComments, setLoadedComments] = useState({});

  useEffect(() => {
    fetchUpdates();
    fetchUsers();
  }, []);

  useEffect(() => {
    updates.forEach(update => {
      if (!loadedComments[update.id] && update.comments_count > 0) {
        fetchComments(update.id);
      }
    });
  }, [updates, loadedComments]);

  const fetchUpdates = async () => {
    setLoading(true);
    try {
      const response = await getSocialUpdates();
      setUpdates(response);
    } catch (error) {
      console.error('Error fetching social updates:', error);
      message.error('Failed to load team updates');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      if (response && Array.isArray(response.users)) {
        const userDetailsPromises = response.users.map(user => getUserDetailsById(user.id));
        const userDetails = await Promise.all(userDetailsPromises);
        setUsers(userDetails);
      } else {
        console.error('Unexpected user data format:', response);
        message.error('Failed to load users: Unexpected data format');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  const handleCreateUpdate = async () => {
    try {
      const updateData = new FormData();
      
      if (!newUpdate.trim() && fileList.length === 0) {
        message.error('Please enter some content or attach a file.');
        return;
      }
  
      if (newUpdate.trim()) {
        updateData.append('content', newUpdate.trim());
      }
  
      if (fileList.length > 0) {
        const file = fileList[0].originFileObj;
        updateData.append('file', file, file.name);
      }
  
      await createSocialUpdate(updateData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      });
  
      message.success('Team update created successfully');
      setNewUpdate('');
      setFileList([]);
      setUploadProgress(0);
      fetchUpdates();
    } catch (error) {
      console.error('Error creating team update:', error);
      message.error('Failed to create team update: ' + (error.response?.data?.message || error.message));
      setUploadProgress(0);
    }
  };

  const handleLike = async (updateId, isLiked) => {
    try {
      if (isLiked) {
        await unlikeSocialUpdate(updateId);
      } else {
        await likeSocialUpdate(updateId);
      }
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId
            ? { ...update, is_liked: !isLiked, likes_count: isLiked ? update.likes_count - 1 : update.likes_count + 1 }
            : update
        )
      );
    } catch (error) {
      console.error('Error liking/unliking update:', error);
      message.error('Failed to like/unlike update');
    }
  };

  const handlePin = async (updateId) => {
    try {
      await pinSocialUpdate(updateId);
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId ? { ...update, pinned: true } : update
        )
      );
      message.success('Update pinned successfully');
    } catch (error) {
      console.error('Error pinning update:', error);
      message.error('Failed to pin update');
    }
  };

  const handleUnpin = async (updateId) => {
    try {
      await unpinSocialUpdate(updateId);
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId ? { ...update, pinned: false } : update
        )
      );
      message.success('Update unpinned successfully');
    } catch (error) {
      console.error('Error unpinning update:', error);
      message.error('Failed to unpin update');
    }
  };

  const handleDelete = async (updateId) => {
    try {
      await deleteSocialUpdate(updateId);
      setUpdates(prevUpdates => prevUpdates.filter(update => update.id !== updateId));
      message.success('Update deleted successfully');
    } catch (error) {
      console.error('Error deleting update:', error);
      message.error('Failed to delete update');
    }
  };

  const fetchComments = async (updateId) => {
    try {
      const comments = await getComments(updateId, 3); // Fetch only 3 most recent comments
      setLoadedComments(prev => ({
        ...prev,
        [updateId]: comments
      }));
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId
            ? { ...update, comments }
            : update
        )
      );
    } catch (error) {
      console.error('Error fetching comments:', error);
      message.error('Failed to load comments');
    }
  };

  const handleAddComment = async (updateId) => {
    const commentContent = commentInputs[updateId];
    if (!commentContent || !commentContent.trim()) {
      message.error('Comment cannot be empty');
      return;
    }
    try {
      const newComment = await addComment(updateId, { content: commentContent });
      // Assuming the API returns the full comment object including user details
      setCommentInputs(prev => ({ ...prev, [updateId]: '' }));
      setLoadedComments(prev => ({
        ...prev,
        [updateId]: [newComment, ...(prev[updateId] || [])].slice(0, 3) // Keep only the 3 most recent comments
      }));
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId
            ? { 
                ...update, 
                comments: [newComment, ...(update.comments || [])].slice(0, 3),
                comments_count: update.comments_count + 1 
              }
            : update
        )
      );
      message.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error('Failed to add comment');
    }
  };

  const loadMoreComments = async (updateId) => {
    setLoadingMoreComments(prev => ({ ...prev, [updateId]: true }));
    try {
      const currentComments = updates.find(u => u.id === updateId).comments;
      const newComments = await getComments(updateId, Math.ceil(currentComments.length / 5) + 1, 5);
      setUpdates(prevUpdates =>
        prevUpdates.map(update =>
          update.id === updateId
            ? { ...update, comments: [...update.comments, ...newComments] }
            : update
        )
      );
    } catch (error) {
      console.error('Error loading more comments:', error);
      message.error('Failed to load more comments');
    } finally {
      setLoadingMoreComments(prev => ({ ...prev, [updateId]: false }));
    }
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'pdf':
        return <FilePdfOutlined />;
      case 'doc':
      case 'docx':
        return <FileWordOutlined />;
      case 'xls':
      case 'xlsx':
        return <FileExcelOutlined />;
      default:
        return <FileOutlined />;
    }
  };

  const handleFilePreview = (file) => {
    if (file.type.startsWith('image/')) {
      setPreviewFile(file);
      setPreviewVisible(true);
    } else {
      const link = document.createElement('a');
      link.href = file.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderFilePreview = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const fileIcon = getFileIcon(fileExtension);

    return (
      <div className="file-preview">
        {fileIcon}
        <Text style={{ marginLeft: '8px' }}>{file.name}</Text>
        {file.type.startsWith('image/') ? (
          <Button type="link" onClick={() => handleFilePreview(file)}>
            Preview
          </Button>
        ) : (
          <Button type="link" href={file.url} target="_blank" download>
            Download
          </Button>
        )}
      </div>
    );
  };

  const renderComments = (update) => {
    const comments = loadedComments[update.id] || [];
    
    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={comment => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar 
                    src={comment.profile_image_url} 
                    alt={`${comment.user_name}'s profile`}
                    style={{ 
                      width: 40, 
                      height: 40, 
                      borderRadius: '50%', 
                      objectFit: 'cover' 
                    }}
                  />
                }
                title={comment.user_name}
                description={
                  <>
                    <Text>{comment.content}</Text>
                    <br />
                    <Text type="secondary">{formatDateTimeForDisplay(comment.created_at)}</Text>
                  </>
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: 'No comments yet' }}
        />
        {comments.length < update.comments_count && (
          <Button onClick={() => loadMoreComments(update.id)}>
            Load More Comments
          </Button>
        )}
      </>
    );
  };

  const renderUpdateContent = (update) => (
    <Card
      hoverable
      className={`w-full mb-4 ${update.pinned ? 'bg-blue-50' : ''}`}
      actions={[
        <Tooltip key="like" title={update.is_liked ? "Unlike" : "Like"}>
          <Button 
            icon={<LikeOutlined />} 
            onClick={() => handleLike(update.id, update.is_liked)}
            type={update.is_liked ? "primary" : "default"}
          >
            {update.likes_count} Like{update.likes_count !== 1 ? 's' : ''}
          </Button>
        </Tooltip>,
        <Tooltip key="comment" title="Comment">
          <Button icon={<CommentOutlined />}>
            {update.comments_count} Comment{update.comments_count !== 1 ? 's' : ''}
          </Button>
        </Tooltip>,
        isAdmin && (
          update.pinned ? (
            <Popconfirm
              key="unpin"
              title="Are you sure you want to unpin this update?"
              onConfirm={() => handleUnpin(update.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Unpin">
                <Button icon={<PushpinOutlined />} type="primary">
                  Unpin
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : (
            <Popconfirm
              key="pin"
              title="Are you sure you want to pin this update?"
              onConfirm={() => handlePin(update.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Pin">
                <Button icon={<PushpinOutlined />}>
                  Pin
                </Button>
              </Tooltip>
            </Popconfirm>
          )
        ),
        (isAdmin || update.user_id === currentUser.id) && (
          <Popconfirm
            key="delete"
            title="Are you sure you want to delete this update?"
            onConfirm={() => handleDelete(update.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button icon={<DeleteOutlined />} danger>
                Delete
              </Button>
            </Tooltip>
          </Popconfirm>
        ),
      ]}
    >
      <Card.Meta
      avatar={
        <Avatar
          src={update.profile_image_url}
          alt={`${update.user_name}'s profile image`}
          style={{ 
            width: 50, 
            height: 50, 
            borderRadius: '50%', 
            objectFit: 'cover' 
          }}
        />
      }
      title={
        <div>
          <span>{update.user_name}</span>
          {update.pinned && <Tag color="blue" className="ml-2">Important</Tag>}
        </div>
      }
      description={formatDateTimeForDisplay(update.created_at)}
      />
      <Paragraph className="mt-4">
        <ReactMarkdown>{update.content}</ReactMarkdown>
      </Paragraph>
      {update.media_url && update.media_type.startsWith('image/') && (
        <img src={update.media_url} alt="Update media" className="w-full mt-4 rounded-lg" />
      )}
      {update.media_url && !update.media_type.startsWith('image/') && (
        renderFilePreview({
          name: update.media_name || 'Attachment',
          url: update.media_url,
          type: update.media_type
        })
      )}
      
      <div className="comments-section mt-4">
        <Title level={5}>Comments</Title>
        {renderComments(update)}
        {update.comments_count > 5 && (
          <Button 
            onClick={() => loadMoreComments(update.id)} 
            loading={loadingMoreComments[update.id]}
          >
            Load More Comments
          </Button>
        )}
        <div className="comment-input mt-2">
          <Input.TextArea
            rows={2}
            value={commentInputs[update.id] || ''}
            onChange={(e) => setCommentInputs(prev => ({ ...prev, [update.id]: e.target.value }))}
            placeholder="Write a comment..."
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={() => handleAddComment(update.id)}
            className="mt-2"
          >
            Add Comment
          </Button>
        </div>
      </div>
    </Card>
  );

  const renderUserList = () => (
    <List
      itemLayout="horizontal"
      dataSource={users}
      renderItem={(user) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar src={user.profile_image_url} size={64} />
            }
            title={<a href={`/user/${user.id}`}>{user.display_name}</a>}
            description={
              <>
                <div>{user.designation}</div>
                <div>{user.email}</div>
              </>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <div className="social-updates-container">
      <Row gutter={24}>
        <Col span={16}>
        <div className="mb-8">
  <Title level={2} className="page-header">In-House Team Updates</Title>
  <Card className="update-form">
    <TextArea
      rows={4}
      value={newUpdate}
      onChange={(e) => setNewUpdate(e.target.value)}
      placeholder="What's on your mind?"
    />
    <div className="mt-4 flex justify-between items-center">
      <Upload
        fileList={fileList}
        onChange={({ fileList }) => setFileList(fileList)}
        beforeUpload={() => false}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Upload File/Image/Video</Button>
      </Upload>
      <Button 
        type="primary" 
        onClick={handleCreateUpdate}
        disabled={!newUpdate.trim() && fileList.length === 0}
      >
        Post Update
      </Button>
    </div>
    {uploadProgress > 0 && uploadProgress < 100 && (
      <Progress percent={uploadProgress} status="active" />
    )}
  </Card>
</div>
          <List
            className="updates-list"
            dataSource={updates}
            loading={loading}
            renderItem={renderUpdateContent}
          />
        </Col>
        <Col span={8}>
          <Card title="Users" className="users-card">
            {renderUserList()}
          </Card>
        </Col>
      </Row>

      <Modal
        title="File Preview"
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        footer={null}
        width="80%"
      >
        {previewFile && previewFile.type.startsWith('image/') && (
          <img src={previewFile.url} alt={previewFile.name} style={{ width: '100%' }} />
        )}
      </Modal>
    </div>
  );
};

SocialUpdates.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    role: PropTypes.string.isRequired,
    profile_image_url: PropTypes.string,
    display_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default SocialUpdates;