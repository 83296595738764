import React, { useState, useEffect } from 'react';
import { Radio, Tooltip, Select, Spin } from 'antd';
import { TableOutlined, AppstoreOutlined } from '@ant-design/icons';
import { 
  ChevronDown, 
  ChevronUp, 
  Users, 
  CalendarCheck, 
  TrendingUp, 
  DollarSign,
  UserPlus,
  Calendar,
  PhoneCall,
  XCircle
} from 'lucide-react';
import { getCoordinatorMonthlyStats, getCoordinators } from './../apiService';

const { Option } = Select;

// Stats Item Component with icon
const StatItem = ({ icon: Icon, label, value, color }) => (
  <div className={`flex items-center gap-3 px-4 py-2 bg-${color}-50 rounded-lg`}>
    <div className={`p-2 bg-${color}-100 rounded-full`}>
      <Icon className={`w-5 h-5 text-${color}-600`} />
    </div>
    <div>
      <p className="text-sm text-gray-600 m-0">{label}</p>
      <p className={`text-lg font-semibold text-${color}-700 m-0`}>{value}</p>
    </div>
  </div>
);

// Compact ViewToggle Component
const ViewToggle = ({ currentView, onViewChange }) => (
  <Radio.Group 
    value={currentView} 
    onChange={(e) => onViewChange(e.target.value)}
    size="small"
    className="ml-4"
  >
    <Tooltip title="Table View">
      <Radio.Button value="table">
        <TableOutlined />
      </Radio.Button>
    </Tooltip>
    <Tooltip title="Card View">
      <Radio.Button value="card">
        <AppstoreOutlined />
      </Radio.Button>
    </Tooltip>
  </Radio.Group>
);

// Detailed Stats Item Component
const DetailedStatItem = ({ label, value }) => (
  <div className="flex items-center justify-between p-2 hover:bg-gray-50 rounded">
    <span className="text-sm text-gray-600">{label}</span>
    <span className="font-medium">{value}</span>
  </div>
);

// Stats Section Component
const StatSection = ({ title, icon: Icon, stats, isOpen, onToggle }) => (
  <div className="border rounded-lg mb-2 bg-white shadow-sm">
    <button
      onClick={onToggle}
      className="w-full flex items-center justify-between p-3 hover:bg-gray-50 transition-colors"
    >
      <div className="flex items-center gap-2">
        <Icon className="w-5 h-5 text-gray-600" />
        <h4 className="font-medium text-gray-800 m-0">{title}</h4>
      </div>
      {isOpen ? (
        <ChevronUp className="w-4 h-4 text-gray-500" />
      ) : (
        <ChevronDown className="w-4 h-4 text-gray-500" />
      )}
    </button>
    
    {isOpen && (
      <div className="p-2 border-t divide-y divide-gray-100">
        {stats.map(({ label, value }) => (
          <DetailedStatItem key={label} label={label} value={value} />
        ))}
      </div>
    )}
  </div>
);

// Full Stats Modal Component
const FullStatsModal = ({ stats, loading }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (loading) return <Spin className="block w-full my-4" />;
  if (!stats) return null;

  const sections = [
    {
      title: "Lead Overview",
      data: [
        { label: "Total Leads", value: stats.total_leads },
        { label: "New This Month", value: stats.new_leads_this_month },
        { label: "Today's Assigned", value: stats.leads_assigned_today },
        { label: "This Week's", value: stats.leads_assigned_this_week }
      ]
    },
    {
      title: "Demo Status",
      data: [
        { label: "Booked", value: stats.demo_booked },
        { label: "Completed", value: stats.demos_done_this_month },
        { label: "Missed", value: stats.demo_missed }
      ]
    },
    {
      title: "Conversion Metrics",
      data: [
        { label: "Demo to Admission", value: `${stats.demo_conversion_rate}%` },
        { label: "Lead to Admission", value: `${stats.lead_conversion_rate}%` },
        { label: "Monthly Admissions", value: stats.admissions_this_month },
        { label: "Total Closed", value: stats.total_closed }
      ]
    },
    {
      title: "Financial & Other",
      data: [
        { label: "Revenue", value: `₹${stats.revenue_this_month}` },
        { label: "Follow Ups", value: stats.follow_up },
        { label: "Not Picked", value: stats.not_interested },
        { label: "Unconfirmed", value: stats.unconfirmed }
      ]
    }
  ];

  
  return (
    <div className={`rounded-lg shadow-sm mt-4 bg-gradient-to-r from-purple-600 to-red-500`}>
    {/* Header Section */}
    <div 
      className={`flex items-center justify-between cursor-pointer p-4 transition-all duration-200 ${
        isExpanded ? 'bg-gradient-to-r from-purple-600 to-red-500' : 'hover:from-purple-700 hover:to-red-600'
      } rounded-t-lg`}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <h3 className={`font-medium m-0 text-white text-lg`}>
        Detailed Statistics
      </h3>
      {isExpanded ? (
        <ChevronUp className="w-5 h-5 text-white" />
      ) : (
        <ChevronUp className="w-5 h-5 text-white" />
      )}
    </div>
  
    {/* Expandable Content */}
    <div
      className={`transition-all duration-500 ease-in-out overflow-hidden ${
        isExpanded ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
      }`}
      style={{ transitionProperty: 'max-height, opacity' }}
    >
      <div 
        className={`grid grid-cols-1 md:grid-cols-4 gap-4 p-6 border-t bg-white rounded-b-lg`}
      >
        {sections.map((section) => (
          <div key={section.title} className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-medium mb-3 text-purple-700">{section.title}</h4>
            <div className="space-y-2">
              {section.data.map((item) => (
                <div key={item.label} className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">{item.label}</span>
                  <span className="font-medium text-gray-900">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  

  
);
};

const LeadsManagementPage = ({ currentUser }) => {
  const [currentView, setCurrentView] = useState('table');
  const [coordinators, setCoordinators] = useState([]);
  const [selectedCoordinator, setSelectedCoordinator] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  const TableView = React.lazy(() => import('./LeadsTableView'));
  const CardView = React.lazy(() => import('./LeadsCardView'));

  // Fetch coordinators for admin users
  useEffect(() => {
    const fetchCoordinators = async () => {
      if (currentUser.role === 'administrator' || currentUser.role === 'crm_admin') {
        try {
          const response = await getCoordinators();
          if (Array.isArray(response)) {
            setCoordinators(response);
          } else if (Array.isArray(response.coordinators)) {
            setCoordinators(response.coordinators);
          }
        } catch (error) {
          console.error('Error fetching coordinators:', error);
        }
      }
    };
    fetchCoordinators();
  }, [currentUser]);

  // Set coordinator ID for non-admin users
  useEffect(() => {
    if (currentUser.role === 'crm_executive') {
      setSelectedCoordinator(currentUser.user_id);
    }
  }, [currentUser]);

  // Fetch stats
  useEffect(() => {
    const fetchStats = async () => {
      const coordId = currentUser.role === 'crm_executive' ? currentUser.user_id : selectedCoordinator;
      
      if (coordId) {
        setLoading(true);
        try {
          const data = await getCoordinatorMonthlyStats(
            coordId,
            selectedMonth,
            selectedYear
          );
          setStats(data);
        } catch (error) {
          console.error('Error fetching coordinator stats:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchStats();
  }, [currentUser, selectedCoordinator, selectedMonth, selectedYear]);

  return (
    <div className="p-4">
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="flex flex-wrap items-center gap-4 mb-6">
          {/* Controls Section */}
          <div className="flex items-center gap-4">
            <h1 className="text-xl font-bold m-0">Choose view</h1>
            <ViewToggle 
              currentView={currentView} 
              onViewChange={setCurrentView}
            />
          
            {(currentUser.role === 'administrator' || currentUser.role === 'crm_admin') && (
              <Select
                placeholder="Select Coordinator"
                onChange={setSelectedCoordinator}
                value={selectedCoordinator}
                style={{ width: 200 }}
              >
                {coordinators.map(coordinator => (
                  <Option key={coordinator.id} value={coordinator.user_id}>
                    {coordinator.name}
                  </Option>
                ))}
              </Select>
            )}
            
            <Select
              value={selectedMonth}
              onChange={setSelectedMonth}
              style={{ width: 120 }}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <Option key={i + 1} value={i + 1}>
                  {new Date(2000, i, 1).toLocaleString('default', { month: 'short' })}
                </Option>
              ))}
            </Select>
            
            <Select
              value={selectedYear}
              onChange={setSelectedYear}
              style={{ width: 100 }}
            >
              {Array.from({ length: 5 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return <Option key={year} value={year}>{year}</Option>;
              })}
            </Select>
          </div>

          {/* Quick Stats Section */}
          {stats && !loading && (
            <div className="flex flex-wrap gap-4 ml-auto">
              <StatItem 
                icon={Users}
                label="Total Leads"
                value={stats.total_leads}
                color="slate"
              />
              <StatItem 
                icon={UserPlus}
                label="New Leads"
                value={stats.new_leads_this_month}
                color="blue"
              />
              <StatItem 
                icon={CalendarCheck}
                label="Demos Done"
                value={stats.demos_done_this_month}
                color="green"
              />
              <StatItem 
                icon={TrendingUp}
                label="Lead Conversion"
                value={`${stats.lead_conversion_rate}%`}
                color="purple"
              />
              
              <StatItem 
                icon={Users}
                label="Admissions"
                value={stats.admissions_this_month}
                color="emerald"
              />
              <StatItem 
                icon={DollarSign}
                label="Revenue"
                value={`₹${stats.revenue_this_month}`}
                color="amber"
              />
            </div>
          )}
        </div>

        {/* Detailed Stats Modal */}
        <FullStatsModal stats={stats} loading={loading} />

        {/* Table/Card View */}
        <div className="mt-4">
          <React.Suspense fallback={
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          }>
            {currentView === 'table' ? (
              <TableView 
                currentUser={currentUser} 
                selectedCoordinator={selectedCoordinator}
              />
            ) : (
              <CardView 
                currentUser={currentUser}
                selectedCoordinator={selectedCoordinator}
              />
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
};

export default LeadsManagementPage;