import React, { useState, useEffect, useCallback } from 'react';
import { 
  Table, 
  Button, 
  Modal, 
  Form, 
  Input, 
  Select, 
  message, 
  Space, 
  Card,
  Popconfirm,
  Typography,
  Tag,
  Tooltip
} from 'antd';
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { 
  getCoordinatorCycles, 
  createCoordinatorCycle, 
  updateCoordinatorCycle,
  deleteCoordinatorCycle,
  getUserDetailsById,
  getAllUsers
} from '../apiService';

const { Option } = Select;
const { Title, Text } = Typography;

const CoordinatorCycles = () => {
  const [cycles, setCycles] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingCycle, setEditingCycle] = useState(null);
  const [userNames, setUserNames] = useState({});
  const [form] = Form.useForm();

  const fetchUserName = useCallback(async (userId) => {
    if (!userNames[userId] && userId) {
      try {
        const userDetails = await getUserDetailsById(userId);
        setUserNames(prev => ({
          ...prev,
          [userId]: userDetails.display_name
        }));
      } catch (error) {
        console.error(`Error fetching user details for ID ${userId}:`, error);
        setUserNames(prev => ({
          ...prev,
          [userId]: 'Unknown'
        }));
      }
    }
  }, [userNames]);

  const fetchCycles = async () => {
    setLoading(true);
    try {
      const data = await getCoordinatorCycles();
      setCycles(data.map(cycle => ({
        ...cycle,
        key: cycle.id
      })));
      
      // Fetch names for all coordinators in cycles
      data.forEach(cycle => {
        cycle.coordinator_ids.forEach(id => fetchUserName(id));
        fetchUserName(cycle.final_coordinator_id);
      });
    } catch (error) {
      message.error('Failed to fetch cycles');
    }
    setLoading(false);
  };

  const fetchCoordinators = async () => {
    try {
      const response = await getAllUsers();
      // Check if response has the users property and filter for relevant roles
      if (response?.users) {
        const filteredCoordinators = response.users.filter(user => 
          user.role === 'crm_executive' || user.role === 'administrator'
        );
        setCoordinators(filteredCoordinators);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      message.error('Failed to fetch coordinators');
    }
  };

  useEffect(() => {
    fetchCycles();
    fetchCoordinators();
  }, []);

  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        name: values.name,
        coordinator_ids: values.coordinator_ids,
        final_coordinator_id: values.final_coordinator_id
      };

      if (editingCycle) {
        await updateCoordinatorCycle(editingCycle.id, payload);
        message.success('Cycle updated successfully');
      } else {
        await createCoordinatorCycle(payload);
        message.success('Cycle created successfully');
      }
      setModalVisible(false);
      form.resetFields();
      setEditingCycle(null);
      fetchCycles();
    } catch (error) {
      message.error('Failed to save cycle');
      console.error('Error saving cycle:', error);
    }
  };

  const handleDelete = async (cycleId) => {
    try {
      await deleteCoordinatorCycle(cycleId);
      message.success('Cycle deleted successfully');
      fetchCycles();
    } catch (error) {
      message.error('Failed to delete cycle');
      console.error('Error deleting cycle:', error);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text) => <Title level={5}>{text}</Title>
    },
    {
      title: (
        <Space>
          <span>Coordinator Flow</span>
          <Tooltip title="Leads will be automatically assigned to coordinators in this sequence when marked as NP3">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'coordinator_ids',
      key: 'coordinators',
      width: '45%',
      render: (coordinatorIds) => (
        <Space wrap>
          {coordinatorIds.map((id, index) => (
            <React.Fragment key={id}>
              <Tag color="blue">
                {userNames[id] || 'Loading...'}
              </Tag>
              {index < coordinatorIds.length - 1 && (
                <ArrowRightOutlined style={{ color: '#1890ff' }} />
              )}
            </React.Fragment>
          ))}
          <ArrowRightOutlined style={{ color: '#52c41a' }} />
        </Space>
      ),
    },
    {
      title: (
        <Space>
          <span>Final Coordinator</span>
          <Tooltip title="Receives leads after the last coordinator marks them as NP3">
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      ),
      dataIndex: 'final_coordinator_id',
      key: 'finalCoordinator',
      width: '20%',
      render: (id) => (
        <Tag color="green">
          {userNames[id] || 'Loading...'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Cycle">
            <Button 
              icon={<EditOutlined />}
              type="primary"
              ghost
              onClick={() => {
                setEditingCycle(record);
                form.setFieldsValue({
                  name: record.name,
                  coordinator_ids: record.coordinator_ids,
                  final_coordinator_id: record.final_coordinator_id,
                });
                setModalVisible(true);
              }}
            />
          </Tooltip>
          <Popconfirm
            title="Delete Cycle"
            description="Are you sure you want to delete this cycle? This action cannot be undone."
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <Tooltip title="Delete Cycle">
              <Button icon={<DeleteOutlined />} danger />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Card
        title={<Title level={2}>Coordinator Cycles Management</Title>}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingCycle(null);
              form.resetFields();
              setModalVisible(true);
            }}
          >
            Create New Cycle
          </Button>
        }
      >
        <Table 
          columns={columns} 
          dataSource={cycles}
          loading={loading}
          pagination={false}
          scroll={{ x: 1200 }}
        />

        <Modal
          title={editingCycle ? 'Edit Cycle' : 'Create New Cycle'}
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            setEditingCycle(null);
            form.resetFields();
          }}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleCreateOrUpdate}
          >
            <Form.Item
              name="name"
              label="Cycle Name"
              rules={[{ required: true, message: 'Please enter cycle name' }]}
            >
              <Input placeholder="Enter cycle name" />
            </Form.Item>

            <Form.Item
              name="coordinator_ids"
              label={
                <Space>
                  <span>Coordinators (in order)</span>
                  <Tooltip title="Select coordinators in the order they should handle leads">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: 'Please select coordinators' }]}
            >
              <Select
                mode="multiple"
                placeholder="Select coordinators in order"
                showSearch
                filterOption={(input, option) =>
                  option?.children?.props?.children?.[0].toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
              >
                {coordinators.map(coordinator => (
                  <Option 
                    key={coordinator.id} 
                    value={coordinator.id}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{coordinator.display_name}</span>
                      <Text type="secondary">{coordinator.role === 'administrator' ? 'Admin' : 'Coordinator'}</Text>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="final_coordinator_id"
              label={
                <Space>
                  <span>Final Coordinator</span>
                  <Tooltip title="This coordinator will receive leads after the last coordinator marks them as NP3">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              rules={[{ required: true, message: 'Please select final coordinator' }]}
            >
              <Select
                placeholder="Select final coordinator"
                showSearch
                filterOption={(input, option) =>
                  option?.children?.props?.children?.[0].toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: '100%' }}
              >
                {coordinators.map(coordinator => (
                  <Option 
                    key={coordinator.id} 
                    value={coordinator.id}
                    disabled={form.getFieldValue('coordinator_ids')?.includes(coordinator.id)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{coordinator.display_name}</span>
                      <Text type="secondary">{coordinator.role === 'administrator' ? 'Admin' : 'Coordinator'}</Text>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.List name="coordinator_preview">
              {() => (
                <div style={{ marginBottom: '24px', padding: '16px', background: '#f5f5f5', borderRadius: '8px' }}>
                  <Title level={5}>Cycle Preview:</Title>
                  <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <div>
                      <Text strong>Lead Flow:</Text>
                      <div style={{ marginTop: '8px' }}>
                        {form.getFieldValue('coordinator_ids')?.map((id, index) => (
                          <React.Fragment key={id}>
                            <Tag color="blue">
                              {coordinators.find(c => c.id === id)?.display_name || 'Unknown'}
                            </Tag>
                            {index < form.getFieldValue('coordinator_ids').length - 1 && (
                              <ArrowRightOutlined style={{ margin: '0 8px', color: '#1890ff' }} />
                            )}
                          </React.Fragment>
                        ))}
                        {form.getFieldValue('coordinator_ids')?.length > 0 && 
                         form.getFieldValue('final_coordinator_id') && (
                          <>
                            <ArrowRightOutlined style={{ margin: '0 8px', color: '#52c41a' }} />
                            <Tag color="green">
                              {coordinators.find(c => c.id === form.getFieldValue('final_coordinator_id'))?.display_name || 'Unknown'}
                            </Tag>
                          </>
                        )}
                      </div>
                    </div>
                  </Space>
                </div>
              )}
            </Form.List>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  {editingCycle ? 'Update Cycle' : 'Create Cycle'}
                </Button>
                <Button onClick={() => {
                  setModalVisible(false);
                  setEditingCycle(null);
                  form.resetFields();
                }}>
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default CoordinatorCycles;