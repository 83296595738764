import React, { useState, useEffect, useCallback } from 'react';
import { Card, Select, message, Spin, Typography, Row, Col, Statistic, Tabs, Badge } from 'antd';
import { 
  UserOutlined, PhoneOutlined, CalendarOutlined, CheckCircleOutlined, 
  CloseCircleOutlined, ExclamationCircleOutlined, QuestionCircleOutlined,
  DollarCircleOutlined, TeamOutlined, ArrowUpOutlined, ArrowDownOutlined
} from '@ant-design/icons';
import { getAllUsers, getCurrentUserDetails, getCoordinators, getDashboardData } from '../apiService';
import { getStartOfMonth } from '../utils/dateUtils';
import moment from 'moment';

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const Reports = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [allowedUsers, setAllowedUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const parseRevenue = (revenueString) => {
    if (!revenueString) return 0;
    return parseFloat(revenueString.replace('₹', '').replace(/,/g, '')) || 0;
  };

  const fetchCurrentUser = async () => {
    try {
      const user = await getCurrentUserDetails();
      setCurrentUser(user);
      setIsAdmin(user.role === 'administrator' || user.role === 'crm_admin');
    } catch (error) {
      console.error('Error fetching current user:', error);
      setError('Failed to load user details');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users');
    }
  };

  const fetchCoordinators = async () => {
    try {
      const response = await getCoordinators();
      setCoordinators(response);
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      setError('Failed to load coordinators');
    }
  };

  const fetchStats = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const dashboardData = await getDashboardData();
      const coordinatorStats = dashboardData.coordinator_stats.reduce((acc, coord) => {
        acc[coord.id] = {
          name: coord.name,
          totalFollowUps: parseInt(coord.total_follow_ups) || 0,
          demoBooked: parseInt(coord.demo_booked) || 0,
          demoMissed: parseInt(coord.demo_missed) || 0,
          demoDone: parseInt(coord.demo_done) || 0,
          closed: parseInt(coord.closed) || 0,
          totalNP: parseInt(coord.total_np) || 0,
          unconfirmed: parseInt(coord.unconfirmed) || 0,
          untouched: parseInt(coord.untouched) || 0,
          revenueGenerated: parseRevenue(coord.revenue_generated),
          expectedRevenue: parseRevenue(coord.expected_revenue),
          totalAdmissions: parseInt(coord.current_admissions) || 0,
          totalLeadsAssigned: parseInt(coord.current_leads) || 0,
          leadsAssignedThisMonth: parseInt(coord.current_leads) || 0,
          currentConversionRate: parseFloat(coord.current_conversion_rate) || 0,
          previousConversionRate: parseFloat(coord.previous_conversion_rate) || 0,
          currentLeads: parseInt(coord.current_leads) || 0,
          previousLeads: parseInt(coord.previous_leads) || 0,
          currentAdmissions: parseInt(coord.current_admissions) || 0,
          previousAdmissions: parseInt(coord.previous_admissions) || 0,
        };
        return acc;
      }, {});
      setStats(coordinatorStats);
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to load statistics');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCurrentUser();
    fetchUsers();
    fetchCoordinators();
  }, []);

  useEffect(() => {
    if (coordinators.length > 0) {
      fetchStats();
    }
  }, [coordinators, fetchStats]);

  const handleAllowedUsersChange = (selectedUserIds) => {
    setAllowedUsers(selectedUserIds);
    message.success('Allowed users updated successfully');
  };

  const renderKeyStats = (statData) => (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      <Col span={8}>
        <Card hoverable style={{ background: '#1890ff', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Total Admissions</Text>}
            value={statData.totalAdmissions}
            valueStyle={{ color: 'white', fontSize: 36 }}
            prefix={<TeamOutlined />}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card hoverable style={{ background: '#52c41a', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Expected Revenue</Text>}
            value={statData.expectedRevenue}
            valueStyle={{ color: 'white', fontSize: 36 }}
            prefix={<ArrowUpOutlined />}
            suffix="₹"
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card hoverable style={{ background: '#722ed1', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Generated Revenue</Text>}
            value={statData.revenueGenerated}
            valueStyle={{ color: 'white', fontSize: 36 }}
            prefix={<DollarCircleOutlined />}
            suffix="₹"
          />
        </Card>
      </Col>
    </Row>
  );

  const renderOtherStats = (statData) => (
    <Row gutter={[16, 16]}>
      {renderStatistic("Follow Ups", statData.totalFollowUps, <PhoneOutlined />, "#13c2c2")}
      {renderStatistic("Demo Booked", statData.demoBooked, <CalendarOutlined />, "#faad14")}
      {renderStatistic("Demo Done", statData.demoDone, <CheckCircleOutlined />, "#52c41a")}
      {renderStatistic("Demo Missed", statData.demoMissed, <CloseCircleOutlined />, "#ff4d4f")}
      {renderStatistic("Closed", statData.closed, <ExclamationCircleOutlined />, "#eb2f96")}
      {renderStatistic("Total NP", statData.totalNP, <QuestionCircleOutlined />, "#2f54eb")}
      {renderStatistic("Unconfirmed", statData.unconfirmed, <UserOutlined />, "#fa8c16")}
      {renderStatistic("Untouched", statData.untouched, <ExclamationCircleOutlined />, "#a0d911")}
      {renderStatistic("Total Leads Assigned", statData.totalLeadsAssigned, <TeamOutlined />, "#1890ff")}
      {renderStatistic("Leads Assigned This Month", statData.leadsAssignedThisMonth, <CalendarOutlined />, "#52c41a")}
    </Row>
  );

  const renderConversionRates = (statData) => (
    <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
      <Col span={12}>
        <Card hoverable style={{ background: '#1890ff', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Current Month Conversion Rate</Text>}
            value={statData.currentConversionRate}
            precision={2}
            valueStyle={{ color: 'white', fontSize: 36 }}
            suffix="%"
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card hoverable style={{ background: '#722ed1', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Previous Month Conversion Rate</Text>}
            value={statData.previousConversionRate}
            precision={2}
            valueStyle={{ color: 'white', fontSize: 36 }}
            suffix="%"
          />
        </Card>
      </Col>
    </Row>
  );

  const renderLeadsComparison = (statData) => (
    <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
      <Col span={12}>
        <Card hoverable style={{ background: '#52c41a', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Current Month Leads</Text>}
            value={statData.currentLeads}
            valueStyle={{ color: 'white', fontSize: 36 }}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card hoverable style={{ background: '#faad14', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Previous Month Leads</Text>}
            value={statData.previousLeads}
            valueStyle={{ color: 'white', fontSize: 36 }}
          />
        </Card>
      </Col>
    </Row>
  );

  const renderAdmissionsComparison = (statData) => (
    <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
      <Col span={12}>
        <Card hoverable style={{ background: '#13c2c2', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Current Month Admissions</Text>}
            value={statData.currentAdmissions}
            valueStyle={{ color: 'white', fontSize: 36 }}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card hoverable style={{ background: '#eb2f96', height: '100%' }}>
          <Statistic
            title={<Text strong style={{ color: 'white', fontSize: 18 }}>Previous Month Admissions</Text>}
            value={statData.previousAdmissions}
            valueStyle={{ color: 'white', fontSize: 36 }}
          />
        </Card>
      </Col>
    </Row>
  );

  const renderStatistic = (title, value, icon, color) => (
    <Col xs={12} sm={8} md={6}>
      <Card bordered={false} style={{ background: color, borderRadius: '8px' }}>
        <Statistic
          title={<span style={{ color: 'white' }}>{title}</span>}
          value={value}
          valueStyle={{ color: 'white' }}
          prefix={icon}
        />
      </Card>
    </Col>
  );

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <Text type="danger">{error}</Text>;
  }

  if (!isAdmin && !allowedUsers.includes(currentUser?.id)) {
    return <Text>You don't have permission to view this page.</Text>;
  }

  return (
    <div className="reports-container" style={{ padding: '24px' }}>
      <Title level={2}>Coordinator Statistics</Title>
      
      {isAdmin && (
        <Card title="Permissions" style={{ marginBottom: 16 }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select users allowed to view reports"
            value={allowedUsers}
            onChange={handleAllowedUsersChange}
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
            ))}
          </Select>
        </Card>
      )}
      
      <Tabs defaultActiveKey="monthly" type="card">
        <TabPane tab={`Monthly Statistics (${moment(getStartOfMonth(new Date())).format('MMM D')} - ${moment().format('MMM D')})`} key="monthly">
          <Tabs tabPosition="left">
            {Object.entries(stats).map(([id, coordinatorData]) => (
              <TabPane tab={coordinatorData.name} key={id}>
                <Card 
                  title={<Badge status="processing" text={`${coordinatorData.name}'s Monthly Statistics`} />}
                  hoverable
                >
                  {renderKeyStats(coordinatorData)}
                  {renderOtherStats(coordinatorData)}
                  {renderConversionRates(coordinatorData)}
                  {renderLeadsComparison(coordinatorData)}
                  {renderAdmissionsComparison(coordinatorData)}
                </Card>
              </TabPane>
            ))}
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Reports;