import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Drawer, List, Button, Input, message, Popconfirm, Typography } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, SaveOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { formatDateTimeForDisplay } from '../utils/dateUtils';
import debounce from 'lodash/debounce';

const { TextArea } = Input;
const { Text } = Typography;

const UserNotes = ({ visible, onClose, token }) => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [newNote, setNewNote] = useState({ topic: '', content: '' });
  const [error, setError] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);

  const api = useMemo(() => axios.create({
    baseURL: 'https://it.nilconnect.in/wp-json/ei-crm/v1',
    headers: { Authorization: `Bearer ${token}` }
  }), [token]);

  const fetchNotes = useCallback(debounce(async () => {
    console.log('Fetching notes...');
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/user-notes');
      console.log('API Response:', response.data);
      if (Array.isArray(response.data)) {
        const formattedNotes = response.data.map(note => ({
          ...note,
          created_at: formatDateTimeForDisplay(note.created_at),
          updated_at: formatDateTimeForDisplay(note.updated_at),
          excerpt: note.content.split(' ').slice(0, 10).join(' ') + (note.content.split(' ').length > 10 ? '...' : '')
        }));
        setNotes(formattedNotes);
        console.log('Notes set:', formattedNotes);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching user notes:', error);
      setError(`Failed to load notes: ${error.message}`);
      message.error('Failed to load notes');
    } finally {
      setLoading(false);
    }
  }, 300), [api]);

  useEffect(() => {
    console.log('Visibility changed:', visible);
    if (visible) {
      fetchNotes();
    }
  }, [visible, fetchNotes]);

  const handleAddNote = async () => {
    if (!newNote.topic.trim() || !newNote.content.trim()) {
      message.error('Topic and content are required');
      return;
    }
    try {
      await api.post('/user-notes', newNote);
      message.success('Note added successfully');
      setNewNote({ topic: '', content: '' });
      fetchNotes();
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note');
    }
  };

  const handleUpdateNote = async () => {
    if (!editingNote.topic.trim() || !editingNote.content.trim()) {
      message.error('Topic and content are required');
      return;
    }
    try {
      await api.put(`/user-notes/${editingNote.id}`, {
        topic: editingNote.topic,
        content: editingNote.content
      });
      message.success('Note updated successfully');
      setEditingNote(null);
      setSelectedNote(null);
      fetchNotes();
    } catch (error) {
      console.error('Error updating note:', error);
      message.error('Failed to update note');
    }
  };

  const handleDeleteNote = async (id) => {
    try {
      await api.delete(`/user-notes/${id}`);
      message.success('Note deleted successfully');
      setSelectedNote(null);
      fetchNotes();
    } catch (error) {
      console.error('Error deleting note:', error);
      message.error('Failed to delete note');
    }
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  const handleBackToList = () => {
    setSelectedNote(null);
    setEditingNote(null);
  };

  const renderNotesList = () => (
    <>
      <div style={{ marginBottom: '20px' }}>
        <Input
          placeholder="New note topic"
          value={newNote.topic}
          onChange={(e) => setNewNote({ ...newNote, topic: e.target.value })}
          style={{ marginBottom: '10px' }}
        />
        <TextArea
          rows={4}
          placeholder="New note content"
          value={newNote.content}
          onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
          style={{ marginBottom: '10px' }}
        />
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNote}>
          Add Note
        </Button>
      </div>
      <List
        loading={loading}
        dataSource={notes}
        renderItem={(note) => (
          <List.Item
            actions={[
              <Button key="view" onClick={() => handleNoteClick(note)}>View</Button>,
              <Popconfirm
                key="delete"
                title="Are you sure you want to delete this note?"
                onConfirm={() => handleDeleteNote(note.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} danger />
              </Popconfirm>
            ]}
          >
            <List.Item.Meta
              title={note.topic}
              description={note.excerpt}
            />
          </List.Item>
        )}
      />
    </>
  );

  const renderNoteDetail = () => (
    <div>
      <Button icon={<ArrowLeftOutlined />} onClick={handleBackToList} style={{ marginBottom: '20px' }}>
        Back to List
      </Button>
      {editingNote ? (
        <>
          <Input
            value={editingNote.topic}
            onChange={(e) => setEditingNote({ ...editingNote, topic: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextArea
            rows={6}
            value={editingNote.content}
            onChange={(e) => setEditingNote({ ...editingNote, content: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <Button type="primary" icon={<SaveOutlined />} onClick={handleUpdateNote}>
            Update Note
          </Button>
          <Button onClick={() => setEditingNote(null)} style={{ marginLeft: '10px' }}>
            Cancel
          </Button>
        </>
      ) : (
        <>
          <h2>{selectedNote.topic}</h2>
          <Text>{selectedNote.content}</Text>
          <div style={{ marginTop: '20px' }}>
            <Button icon={<EditOutlined />} onClick={() => setEditingNote(selectedNote)}>
              Edit
            </Button>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Drawer
      title="User Notes"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={400}
    >
      {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      {selectedNote ? renderNoteDetail() : renderNotesList()}
    </Drawer>
  );
};

UserNotes.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default UserNotes;