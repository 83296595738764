import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authenticate } from '../apiService';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const token = await authenticate(email, password);
      onLogin(token);
      navigate(location.state?.from?.pathname || '/social');
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid credentials. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center" 
         style={{ backgroundImage: "url('https://nationalinstituteoflanguage.in/wp-content/uploads/2024/09/aesthetic-scenery-3840x2160-14711-scaled.jpg')" }}>
      <div className="absolute top-4 left-4">
        <img src="https://nationalinstituteoflanguage.in/wp-content/uploads/2024/09/InConnect-1.png" alt="Ebolt" className="h-12" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <div className="text-center mb-6">
          <div className="bg-gray-100 rounded-full p-3 inline-block">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
            </svg>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center">Sign in with email</h2>
        <p className="text-center text-gray-600 mb-6">It's a new day! Let's start afresh, let's win some people and some business.</p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="email"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="text-right mb-4">
            <a href="#" className="text-sm text-blue-600 hover:underline">Forgot password?</a>
          </div>
          {error && (
            <div className="text-red-500 text-sm mb-4">{error}</div>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gray-900 text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50"
          >
            {isLoading ? 'Signing In...' : 'Get Started'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;