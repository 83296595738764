import moment from 'moment-timezone';

// Set the default timezone to IST (Indian Standard Time)
moment.tz.setDefault('Asia/Kolkata');

export const formatDateForAPI = (date) => {
  return moment(date).format('YYYY-MM-DD');
};



export const formatTimeForAPI = (time) => {
  return moment(time).format('HH:mm:ss');
};

export const formatDateTimeForAPI = (dateTime) => {
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
};

export const formatDateForDisplay = (date) => {
  return moment(date).format('D-MMM-YYYY');
};

export const formatTimeForDisplay = (time) => {
  return moment(time, 'HH:mm:ss').format('hh:mm A');
};

export const formatDateTimeForDisplay = (dateTime) => {
  return moment(dateTime).format('D-MMM-YYYY hh:mm A');
};

export const parseDateFromAPI = (date) => {
  return moment(date, 'YYYY-MM-DD').toDate();
};

export const parseTimeFromAPI = (time) => {
  return moment(time, 'HH:mm:ss');
};

export const parseDateTimeFromAPI = (dateTime) => {
  return moment(dateTime, 'YYYY-MM-DD HH:mm:ss').toDate();
};

export const isToday = (date) => {
  return moment(date).isSame(moment(), 'day');
};

export const isThisWeek = (date) => {
  return moment(date).isSame(moment(), 'week');
};

export const isThisMonth = (date) => {
  return moment(date).isSame(moment(), 'month');
};

export const getRelativeTime = (dateTime) => {
  return moment(dateTime).fromNow();
};

export const addDays = (date, days) => {
  return moment(date).add(days, 'days').toDate();
};

export const subtractDays = (date, days) => {
  return moment(date).subtract(days, 'days').toDate();
};

export const getStartOfDay = (date) => {
  return moment(date).startOf('day').toDate();
};

export const getEndOfDay = (date) => {
  return moment(date).endOf('day').toDate();
};

export const getStartOfWeek = (date) => {
  return moment(date).startOf('week').toDate();
};

export const getEndOfWeek = (date) => {
  return moment(date).endOf('week').toDate();
};

export const getStartOfMonth = (date) => {
  return moment(date).startOf('month').toDate();
};

export const getEndOfMonth = (date) => {
  return moment(date).endOf('month').toDate();
};

export const formatDuration = (minutes) => {
  const duration = moment.duration(minutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const mins = duration.minutes();
  return `${hours}h ${mins}m`;
};

export const parseCallDateTimeForAPI = (dateTimeString) => {
  return moment(dateTimeString, 'D-MMM-YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
};

export const getDateDifference = (date1, date2, unit = 'days') => {
  return moment(date1).diff(moment(date2), unit);
};

export const isValidDate = (date, format = 'DD-MMM-YYYY') => {
  return moment(date, format, true).isValid();
};

export const isValidTime = (time) => {
  return moment(time, 'hh:mm:ss A', true).isValid();
};

export const convertToLocalTime = (dateTime) => {
  return moment.utc(dateTime).local().toDate();
};

export const convertToUTC = (dateTime) => {
  return moment(dateTime).utc().toDate();
};

export const formatDateRange = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  
  if (start.isSame(end, 'day')) {
    return `${start.format('D MMM YYYY')}`;
  } else if (start.isSame(end, 'month')) {
    return `${start.format('D')} - ${end.format('D MMM YYYY')}`;
  } else if (start.isSame(end, 'year')) {
    return `${start.format('D MMM')} - ${end.format('D MMM YYYY')}`;
  } else {
    return `${start.format('D MMM YYYY')} - ${end.format('D MMM YYYY')}`;
  }
};

export const getWeekNumber = (date) => {
  return moment(date).week();
};

export const getDayName = (date) => {
  return moment(date).format('dddd');
};

export const getMonthName = (date) => {
  return moment(date).format('MMMM');
};

export const isLeapYear = (year) => {
  return moment([year]).isLeapYear();
};

export const getDaysInMonth = (year, month) => {
  return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
};



export const roundToNearestMinute = (date, nearestMinutes = 5) => {
  return moment(date).startOf('hour').add(Math.round(moment(date).minute() / nearestMinutes) * nearestMinutes, 'minutes').toDate();
};

export default {
  formatDateForAPI,
  formatTimeForAPI,
  formatDateTimeForAPI,
  formatDateForDisplay,
  formatTimeForDisplay,
  formatDateTimeForDisplay,
  parseDateFromAPI,
  parseTimeFromAPI,
  parseDateTimeFromAPI,
  isToday,
  isThisWeek,
  isThisMonth,
  getRelativeTime,
  addDays,
  subtractDays,
  getStartOfDay,
  getEndOfDay,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  formatDuration,
  getDateDifference,
  isValidDate,
  convertToLocalTime,
  convertToUTC,
  formatDateRange,
  getWeekNumber,
  getDayName,
  getMonthName,
  isLeapYear,
  getDaysInMonth,
  roundToNearestMinute
};