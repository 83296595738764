import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, Statistic, Spin, Alert, Typography, Button, Modal, Select, Divider, Collapse, Table } from 'antd';
import { 
    UserOutlined, ReloadOutlined, DollarCircleOutlined, BarChartOutlined, 
    PhoneOutlined, CalendarOutlined, CheckCircleOutlined, CloseCircleOutlined, 
    TrophyOutlined, ArrowUpOutlined, ArrowDownOutlined, TeamOutlined,
    LineChartOutlined, PieChartOutlined, RiseOutlined
} from '@ant-design/icons';
import { 
    BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
    Legend, ResponsiveContainer, Area, AreaChart, PieChart, Pie, Cell 
} from 'recharts';
import { 
    getDashboardData, 
    getCurrentUserDetails, 
    getCoordinatorDetailedStats, 
    getCoordinatorsOverviewStats 
} from '../apiService';

const { Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const Dashboard = () => {
    // Existing state
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCoordinator, setSelectedCoordinator] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalStats, setModalStats] = useState(null);
    const [includeAdminLeads, setIncludeAdminLeads] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);

    // New state for additional API data
    const [detailedStats, setDetailedStats] = useState(null);
    const [overviewStats, setOverviewStats] = useState(null);
    const [loadingAdditional, setLoadingAdditional] = useState(true);

    const fetchAllData = useCallback(async () => {
        setLoading(true);
        setLoadingAdditional(true);
        setError(null);
        
        try {
            const [userData, dashData] = await Promise.all([
                getCurrentUserDetails(),
                getDashboardData()
            ]);
            setCurrentUser(userData);
            setDashboardData(dashData);

            // Fetch additional data based on user role
            if (userData.role === 'administrator' || userData.role === 'crm_admin') {
                const overviewData = await getCoordinatorsOverviewStats();
                setOverviewStats(overviewData);
            } else {
                const detailedData = await getCoordinatorDetailedStats(userData.user_id);
                setDetailedStats(detailedData);
            }
        } catch (err) {
            console.error('Error fetching dashboard data:', err);
            setError('Failed to load dashboard data. Please try again later.');
        } finally {
            setLoading(false);
            setLoadingAdditional(false);
        }
    }, []);

    useEffect(() => {
        fetchAllData();
    }, [fetchAllData]);

    const handleCoordinatorClick = (coordinator) => {
        setSelectedCoordinator(coordinator);
        setModalVisible(true);
        setModalStats(coordinator);
    };

    // Existing visualization functions
    const renderRevenueComparison = () => {
        const { current_month, previous_month } = dashboardData;
        const data = [
            {
                name: 'Previous Month',
                'Generated Revenue': parseFloat(previous_month.generated_revenue),
                'Expected Revenue': parseFloat(previous_month.expected_revenue),
            },
            {
                name: 'Current Month',
                'Generated Revenue': parseFloat(current_month.generated_revenue),
                'Expected Revenue': parseFloat(current_month.expected_revenue),
            },
        ];

        return (
            <Card title="Revenue Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Generated Revenue" fill="#82ca9d" />
                        <Bar dataKey="Expected Revenue" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderLeadComparisonGraph = () => {
        if (!dashboardData || !dashboardData.leads_admissions_comparison) {
            return <Alert message="Lead comparison data is not available" type="warning" />;
        }

        const { current_month, previous_month } = dashboardData.leads_admissions_comparison;
        const data = [
            {
                name: 'Previous Month',
                Leads: previous_month.leads,
                Admissions: previous_month.admissions,
            },
            {
                name: 'Current Month',
                Leads: current_month.leads,
                Admissions: current_month.admissions,
            },
        ];

        const leadChange = ((current_month.leads - previous_month.leads) / previous_month.leads) * 100;
        const admissionChange = ((current_month.admissions - previous_month.admissions) / previous_month.admissions) * 100;

        return (
            <Card title="Lead and Admission Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Leads" fill="#8884d8" />
                        <Bar dataKey="Admissions" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
                <Divider />
                <Row gutter={16}>
                    <Col span={8}>
                        <Statistic
                            title="Lead Change"
                            value={leadChange}
                            precision={2}
                            valueStyle={{ color: leadChange >= 0 ? '#3f8600' : '#cf1322' }}
                            prefix={leadChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Admission Change"
                            value={admissionChange}
                            precision={2}
                            valueStyle={{ color: admissionChange >= 0 ? '#3f8600' : '#cf1322' }}
                            prefix={admissionChange >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            title="Current Month Conversion Rate"
                            value={current_month.conversion_rate}
                            precision={2}
                            valueStyle={{ color: '#1890ff' }}
                            suffix="%"
                        />
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderCoordinatorComparisonTable = () => {
        if (!dashboardData || !dashboardData.coordinator_stats) {
            return <Alert message="Coordinator comparison data is not available" type="warning" />;
        }

        const columns = [
            {
                title: 'Coordinator',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Current Month Leads',
                dataIndex: 'current_leads',
                key: 'current_leads',
            },
            {
                title: 'Current Month Admissions',
                dataIndex: 'current_admissions',
                key: 'current_admissions',
            },
            {
                title: 'Current Conversion Rate',
                dataIndex: 'current_conversion_rate',
                key: 'current_conversion_rate',
                render: (text) => `${text}%`,
            },
            {
                title: 'Previous Month Leads',
                dataIndex: 'previous_leads',
                key: 'previous_leads',
            },
            {
                title: 'Previous Month Admissions',
                dataIndex: 'previous_admissions',
                key: 'previous_admissions',
            },
            {
                title: 'Previous Conversion Rate',
                dataIndex: 'previous_conversion_rate',
                key: 'previous_conversion_rate',
                render: (text) => `${text}%`,
            },
        ];

        return (
            <Card title="Coordinator Comparison">
                <Table 
                    dataSource={dashboardData.coordinator_stats} 
                    columns={columns} 
                    rowKey="id"
                    scroll={{ x: true }}
                />
            </Card>
        );
    };

    const renderCoordinatorPerformanceGraph = (stats) => {
        const data = [
            {
                name: 'Previous Month',
                Leads: parseInt(stats.previous_leads),
                Admissions: parseInt(stats.previous_admissions),
                'Conversion Rate': stats.previous_conversion_rate,
            },
            {
                name: 'Current Month',
                Leads: parseInt(stats.current_leads),
                Admissions: parseInt(stats.current_admissions),
                'Conversion Rate': stats.current_conversion_rate,
            },
        ];

        return (
            <Card title="Your Performance Comparison">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="Leads" stroke="#8884d8" />
                        <Line yAxisId="left" type="monotone" dataKey="Admissions" stroke="#82ca9d" />
                        <Line yAxisId="right" type="monotone" dataKey="Conversion Rate" stroke="#ffc658" />
                    </LineChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderCoordinatorStats = (stats) => (
        <Card className="coordinator-stats-card">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total Admissions" 
                        value={stats.current_admissions} 
                        prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                        valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total Follow Ups" 
                        value={stats.total_follow_ups} 
                        prefix={<PhoneOutlined style={{ color: '#1890ff' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Booked" 
                        value={stats.demo_booked} 
                        prefix={<CalendarOutlined style={{ color: '#52c41a' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Done" 
                        value={stats.demo_done} 
                        prefix={<CheckCircleOutlined style={{ color: '#13c2c2' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Demo Missed" 
                        value={stats.demo_missed} 
                        prefix={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Closed" 
                        value={stats.closed} 
                        prefix={<CheckCircleOutlined style={{ color: '#faad14' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Total NP" 
                        value={stats.total_np} 
                        prefix={<UserOutlined style={{ color: '#722ed1' }} />}
                    />
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Statistic 
                        title="Unconfirmed" 
                        value={stats.unconfirmed}
                        prefix={<TeamOutlined style={{ color: '#1890ff' }} />}
                    />
                </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Statistic
                        title="Expected Revenue"
                        value={stats.expected_revenue}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: '#722ed1' }} />}
                        suffix="₹"
                    />
                </Col>
                <Col span={12}>
                    <Statistic
                        title="Generated Revenue"
                        value={stats.revenue_generated}
                        precision={2}
                        prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
                        suffix="₹"
                    />
                </Col>
            </Row>
        </Card>
    );
    const renderAllTodaysDemos = () => {
        if (!overviewStats?.all_todays_demos?.length) return null;
    
        const columns = [
            {
                title: 'Time',
                dataIndex: 'demo_time',
                key: 'demo_time',
                width: 100,
                sorter: (a, b) => a.demo_time.localeCompare(b.demo_time)
            },
            {
                title: 'Student Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'Coordinator',
                dataIndex: 'coordinator_name',
                key: 'coordinator_name',
                filters: [...new Set(overviewStats.all_todays_demos.map(demo => demo.coordinator_name))].map(name => ({
                    text: name,
                    value: name,
                })),
                onFilter: (value, record) => record.coordinator_name === value,
            },
            {
                title: 'Last Notes',
                dataIndex: 'last_notes',
                key: 'last_notes',
                render: (notes) => notes?.length ? (
                    <ul className="list-disc pl-4">
                        {notes.map((note, index) => (
                            <li key={index}>{note}</li>
                        ))}
                    </ul>
                ) : 'No notes'
            },
        ];
    
        return (
            <Card 
                title={
                    <div className="flex justify-between items-center">
                        <span>Today's Scheduled Demos ({overviewStats.all_todays_demos.length})</span>
                        <Statistic 
                            value={overviewStats.total_demos_today} 
                            prefix={<CalendarOutlined />} 
                            valueStyle={{ color: '#1890ff' }}
                            suffix="Total"
                        />
                    </div>
                }
                className="mb-6"
            >
                <Table 
                    dataSource={overviewStats.all_todays_demos}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    scroll={{ x: true }}
                />
            </Card>
        );
    };

    // New rendering functions for detailed stats
    const renderDetailedStatsCards = () => {
        if (!detailedStats) return null;

        const periodTitles = {
            today: 'Today',
            this_week: 'This Week',
            this_month: 'This Month',
            last_month: 'Last Month'
        };

        return (
            <Card title="Detailed Performance Metrics" className="mb-6">
                <Row gutter={[16, 16]}>
                    {Object.entries(periodTitles).map(([period, title]) => (
                        <Col xs={24} md={6} key={period}>
                            <Card title={title}>
                                <Statistic
                                    title="Leads Approached"
                                    value={detailedStats[period].leads_approached}
                                    prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                                />
                                <Divider />
                                <Statistic
                                    title="Demos Booked"
                                    value={detailedStats[period].demo_booked}
                                    prefix={<CalendarOutlined style={{ color: '#52c41a' }} />}
                                />
                                <Divider />
                                <Statistic
                                    title="Closed Deals"
                                    value={detailedStats[period].closed}
                                    prefix={<CheckCircleOutlined style={{ color: '#faad14' }} />}
                                />
                                <Divider />
                                <Statistic
                                    title="Admissions"
                                    value={detailedStats[period].admission}
                                    prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                                />
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card>
        );
    };

    const renderDetailedStatsChart = () => {
        if (!detailedStats?.daily_stats) return null;

        const dailyData = Object.entries(detailedStats.daily_stats).map(([date, stats]) => ({
            date,
            ...stats
        }));

        return (
            <Card title="Daily Performance Trends" className="mb-6">
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={dailyData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area type="monotone" dataKey="leads_assigned" stackId="1" stroke="#8884d8" fill="#8884d8" name="Leads Assigned" />
                        <Area type="monotone" dataKey="leads_approached" stackId="1" stroke="#82ca9d" fill="#82ca9d" name="Leads Approached" />
                        <Area type="monotone" dataKey="demo_booked" stackId="1" stroke="#ffc658" fill="#ffc658" name="Demos Booked" />
                        <Area type="monotone" dataKey="admissions" stackId="1" stroke="#ff7300" fill="#ff7300" name="Admissions" />
                    </AreaChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderTodaysDemos = () => {
        if (!detailedStats?.todays_demos?.length) return null;

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'Demo Time',
                dataIndex: 'demo_time',
                key: 'demo_time',
            },
            {
                title: 'Last Notes',
                dataIndex: 'last_notes',
                key: 'last_notes',
                render: (notes) => notes.join(', '),
            },
        ];

        return (
            <Card title="Today's Scheduled Demos" className="mb-6">
                <Table 
                    dataSource={detailedStats.todays_demos}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    scroll={{ x: true }}
                />
            </Card>
        );
    };

    // New rendering functions for overview stats
    const renderOverviewStatsCards = () => {
        if (!overviewStats) return null;

        return (
            <Card title="Team Performance Overview" className="mb-6">
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={6}>
                        <Statistic
                            title="Total Demos Today"
                            value={overviewStats.total_demos_today}
                            prefix={<CalendarOutlined style={{ color: '#1890ff' }} />}
                            valueStyle={{ color: '#1890ff' }}
                        />
                    </Col>
                    <Col xs={24} md={6}>
                        <Statistic
                            title="Total Leads Today"
                            value={overviewStats.leads_today.total}
                            prefix={<UserOutlined style={{ color: '#722ed1' }} />}
                            valueStyle={{ color: '#722ed1' }}
                        />
                    </Col>
                    <Col xs={24} md={6}>
                        <Statistic
                            title="Assigned Leads"
                            value={overviewStats.leads_today.assigned}
                            prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                            valueStyle={{ color: '#52c41a' }}
                        />
                    </Col>
                    <Col xs={24} md={6}>
                        <Statistic
                            title="Unassigned Leads"
                            value={overviewStats.leads_today.unassigned}
                            prefix={<CloseCircleOutlined style={{ color: '#f5222d' }} />}
                            valueStyle={{ color: '#f5222d' }}
                        />
                    </Col>
                </Row>
            </Card>
        );
    };

    const renderTeamPerformanceChart = () => {
        if (!overviewStats?.rankings?.this_month) return null;

        const data = overviewStats.rankings.this_month.map(coord => ({
            name: coord.name,
            'Leads Approached': coord.leads_approached,
            'Admissions': coord.admissions,
            'Demos Booked': coord.demo_booked,
            'Conversion Rate': ((coord.admissions / coord.leads_approached) * 100).toFixed(2)
        }));

        return (
            <Card title="Team Monthly Performance Rankings" className="mb-6">
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip />
                        <Legend />
                        <Bar yAxisId="left" dataKey="Leads Approached" fill="#8884d8" />
                        <Bar yAxisId="left" dataKey="Admissions" fill="#82ca9d" />
                        <Bar yAxisId="left" dataKey="Demos Booked" fill="#ffc658" />
                        <Line yAxisId="right" type="monotone" dataKey="Conversion Rate" stroke="#ff7300" />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        );
    };

    const renderMonthlyTotals = () => {
        if (!overviewStats?.monthly_totals) return null;

        const data = [
            { name: 'Leads Assigned', value: overviewStats.monthly_totals.leads_assigned },
            { name: 'Leads Approached', value: overviewStats.monthly_totals.leads_approached },
            { name: 'Demos Booked', value: overviewStats.monthly_totals.demo_booked },
            { name: 'Admissions', value: overviewStats.monthly_totals.admissions },
        ];

        return (
            <Card title="Monthly Performance Distribution" className="mb-6">
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={data}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="stats-summary">
                            {data.map((item, index) => (
                                <Statistic
                                    key={item.name}
                                    title={item.name}
                                    value={item.value}
                                    valueStyle={{ color: COLORS[index % COLORS.length] }}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    };

    if (loading || loadingAdditional) return <Spin size="large" />;
    if (error) return <Alert message="Error" description={error} type="error" showIcon />;
    if (!dashboardData || !currentUser) return null;

    const isAdmin = currentUser.role === 'administrator' || currentUser.role === 'crm_admin';
    const userStats = dashboardData.coordinator_stats.find(coord => coord.id === currentUser.user_id);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <Title level={2}>Dashboard</Title>
                <Button icon={<ReloadOutlined />} onClick={fetchAllData}>Refresh</Button>
            </div>

            {isAdmin ? (
                <>
                    {/* Existing admin view components */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Total Leads"
                                    value={dashboardData.lead_stats.total_leads}
                                    prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                                    valueStyle={{ color: '#1890ff' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Total Admissions"
                                    value={dashboardData.lead_stats.total_admissions}
                                    prefix={<TrophyOutlined style={{ color: '#722ed1' }} />}
                                    valueStyle={{ color: '#722ed1', fontWeight: 'bold', fontSize: '24px' }}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Card>
                                <Statistic
                                    title="Generated Revenue (Current Month)"
                                    value={dashboardData.current_month.generated_revenue}
                                    precision={2}
                                    prefix={<DollarCircleOutlined style={{ color: '#13c2c2' }} />}
                                    suffix="₹"
                                    valueStyle={{ color: '#13c2c2' }}
                                />
                            </Card>
                        </Col>
                    </Row>

                    {/* New overview stats components */}
                    {renderOverviewStatsCards()}
                    {renderAllTodaysDemos()}
                    {renderTeamPerformanceChart()}
                    {renderMonthlyTotals()}

                    {/* Existing comparison graphs */}
                    <Row gutter={[16, 16]} className="mt-6">
                        <Col xs={24} md={12}>
                            {renderLeadComparisonGraph()}
                        </Col>
                        <Col xs={24} md={12}>
                            {renderRevenueComparison()}
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} className="mt-6">
                        <Col xs={24}>
                            {renderCoordinatorComparisonTable()}
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={[16, 16]} className="mt-6">
                        <Col xs={24}>
                            <Card 
                                title="Coordinator Statistics" 
                                extra={
                                    <Select
                                        defaultValue={includeAdminLeads}
                                        style={{ width: 200 }}
                                        onChange={(value) => setIncludeAdminLeads(value)}
                                    >
                                        <Option value={true}>Include Admin Leads</Option>
                                        <Option value={false}>Exclude Admin Leads</Option>
                                    </Select>
                                }
                            >
                                <Collapse>
                                    {(includeAdminLeads ? dashboardData.coordinator_stats : dashboardData.coordinator_stats.filter(stat => stat.id !== 'admin')).map((coordinator) => (
                                        <Panel header={coordinator.name} key={coordinator.id}>
                                            {renderCoordinatorStats(coordinator)}
                                            <Button onClick={() => handleCoordinatorClick(coordinator)} style={{ marginTop: '16px' }}>
                                                View Detailed Stats
                                            </Button>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                </>
            ) : (
                // Coordinator view
                userStats ? (
                    <Row gutter={[16, 16]}>
                        {/* New detailed stats components */}
                        <Col xs={24}>
                            {renderDetailedStatsCards()}
                        </Col>
                        <Col xs={24}>
                            {renderDetailedStatsChart()}
                        </Col>
                        <Col xs={24}>
                            {renderTodaysDemos()}
                        </Col>

                        {/* Existing coordinator components */}
                        <Col xs={24}>
                            <Card title={`Your Statistics (${currentUser.display_name})`}>
                                {renderCoordinatorStats(userStats)}
                            </Card>
                        </Col>
                        <Col xs={24}>
                            {renderCoordinatorPerformanceGraph(userStats)}
                        </Col>
                        <Col xs={24}>
                            <Card title="Your Performance">
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} sm={12}>
                                        <Statistic
                                            title="Current Month Leads"
                                            value={userStats.current_conversion_rate}
                                            precision={2}
                                            suffix="%"
                                            prefix={<BarChartOutlined />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Statistic
                                            title="Previous Conversion Rate"
                                            value={userStats.previous_conversion_rate}
                                            precision={2}
                                            suffix="%"
                                            prefix={<BarChartOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <Alert 
                        message="No Data Available" 
                        description="Your statistics are not available at the moment. Please contact an administrator if you believe this is an error." 
                        type="warning" 
                        showIcon 
                    />
                )
            )}

            {/* Coordinator Details Modal */}
            <Modal
                title={`${selectedCoordinator?.name}'s Detailed Statistics`}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
                width={800}
            >
                {modalLoading ? (
                    <Spin size="large" />
                ) : modalStats ? (
                    <>
                        {renderCoordinatorStats(modalStats)}
                        <Divider />
                        {renderCoordinatorPerformanceGraph(modalStats)}
                    </>
                ) : null}
            </Modal>
        </div>
    );
};

export default Dashboard;