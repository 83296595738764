import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Input, Card, Button, Modal, Form, message, Popconfirm, Typography, Spin, Select, Avatar } from 'antd';
import { FolderOutlined, FileOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { getWikiStructure, createWikiMainSection, updateWikiMainSection, deleteWikiMainSection, createWikiSubsection, updateWikiSubsection, deleteWikiSubsection, createWikiTopic, getWikiTopicContent, updateWikiTopic, deleteWikiTopic, searchWiki, reorderWikiItems, getWikiActivityLog } from '../apiService';

const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { SubMenu } = Menu;
const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const Wiki = () => {
  const [wikiStructure, setWikiStructure] = useState([]);
  const [selectedMainSection, setSelectedMainSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editContent, setEditContent] = useState('');
  const [parentId, setParentId] = useState(null);
  const [activityLog, setActivityLog] = useState([]);
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingItemTitle, setEditingItemTitle] = useState('');
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const fetchWikiStructure = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getWikiStructure();
      setWikiStructure(Array.isArray(response) ? response : []);
      if (response.length > 0) {
        setSelectedMainSection(response[0]);
        if (response[0].subsections && Object.keys(response[0].subsections).length > 0) {
          const firstSubsection = Object.values(response[0].subsections)[0];
          setSelectedSubsection(firstSubsection);
          if (firstSubsection.topics && firstSubsection.topics.length > 0) {
            await handleTopicSelect(firstSubsection.topics[0].id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching wiki structure:', error);
      message.error('Failed to load wiki structure');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchActivityLog = useCallback(async () => {
    try {
      const response = await getWikiActivityLog();
      setActivityLog(response.data);
    } catch (error) {
      console.error('Error fetching activity log:', error);
      message.error('Failed to load activity log');
    }
  }, []);

  useEffect(() => {
    fetchWikiStructure();
    fetchActivityLog();
  }, [fetchWikiStructure, fetchActivityLog]);

  const handleCreate = async (values) => {
    if (!isEditModeEnabled) return;
    try {
      let response;
      switch (modalType) {
        case 'mainSection':
          response = await createWikiMainSection(values);
          break;
        case 'subsection':
          response = await createWikiSubsection({ ...values, main_section_id: parentId });
          break;
        case 'topic':
          response = await createWikiTopic({ ...values, subsection_id: parentId });
          break;
        default:
          throw new Error('Invalid modal type');
      }
      message.success(`${modalType} created successfully`);
      setModalVisible(false);
      form.resetFields();
      setParentId(null);
      fetchWikiStructure();
      fetchActivityLog();
    } catch (error) {
      console.error(`Error creating ${modalType}:`, error);
      message.error(`Failed to create ${modalType}`);
    }
  };

  const handleUpdate = async (values) => {
    if (!isEditModeEnabled) return;
    try {
      switch (modalType) {
        case 'mainSection':
          await updateWikiMainSection(editingItemId, values);
          break;
        case 'subsection':
          await updateWikiSubsection(editingItemId, values);
          break;
        case 'topic':
          await updateWikiTopic({ id: selectedTopic.id, ...values });
          break;
        default:
          throw new Error('Invalid modal type');
      }
      message.success(`${modalType} updated successfully`);
      setModalVisible(false);
      setEditingItemId(null);
      setEditingItemTitle('');
      form.resetFields();
      fetchWikiStructure();
      fetchActivityLog();
      if (modalType === 'topic') {
        setSelectedTopic(prev => ({ ...prev, ...values }));
        setEditMode(false);
      }
    } catch (error) {
      console.error(`Error updating ${modalType}:`, error);
      message.error(`Failed to update ${modalType}`);
    }
  };

  const handleDelete = async (type, id) => {
    if (!isEditModeEnabled) return;
    try {
      switch (type) {
        case 'mainSection':
          await deleteWikiMainSection(id);
          break;
        case 'subsection':
          await deleteWikiSubsection(id);
          break;
        case 'topic':
          await deleteWikiTopic(id);
          break;
        default:
          throw new Error('Invalid delete type');
      }
      message.success(`${type} deleted successfully`);
      fetchWikiStructure();
      fetchActivityLog();
      if (selectedTopic && selectedTopic.id === id) {
        setSelectedTopic(null);
      }
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
      message.error(`Failed to delete ${type}`);
    }
  };

  const handleSearch = async (value) => {
    setSearchQuery(value);
    if (value.trim()) {
      try {
        const results = await searchWiki(value);
        setSearchResults(results.data);
      } catch (error) {
        console.error('Error searching wiki:', error);
        message.error('Failed to search wiki');
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleTopicSelect = async (topicId) => {
    setContentLoading(true);
    try {
      const content = await getWikiTopicContent(topicId);
      setSelectedTopic({ id: topicId, ...content });
      setEditContent(content.content);
      setEditMode(false);
    } catch (error) {
      console.error('Error fetching topic content:', error);
      message.error('Failed to load topic content');
    } finally {
      setContentLoading(false);
    }
  };

  const toggleEditMode = () => {
    if (!isEditModeEnabled) return;
    setEditMode(!editMode);
    if (!editMode) {
      setEditContent(selectedTopic.content);
    }
  };

  const handleEditStart = (id, title, type) => {
    if (!isEditModeEnabled) return;
    setEditingItemId(id);
    setEditingItemTitle(title);
    setModalType(type);
    form.setFieldsValue({ title: title });
    setModalVisible(true);
  };

  const handleEditSave = async (id, type) => {
    if (!isEditModeEnabled) return;
    try {
      const values = await form.validateFields();
      if (type === 'mainSection') {
        await updateWikiMainSection(id, values);
      } else if (type === 'subsection') {
        await updateWikiSubsection(id, values);
      } else if (type === 'topic') {
        await updateWikiTopic({ id, ...values });
      }
      message.success('Title updated successfully');
      setEditingItemId(null);
      setEditingItemTitle('');
      setModalVisible(false);
      fetchWikiStructure();
      fetchActivityLog();
    } catch (error) {
      console.error('Error updating title:', error);
      message.error('Failed to update title');
    }
  };

  const renderMainSections = () => (
    <Menu mode="inline" selectedKeys={[selectedMainSection?.id]} defaultOpenKeys={wikiStructure.map(section => section.id)}>
      {wikiStructure.map((section) => (
        <Menu.Item 
          key={section.id} 
          icon={<FolderOutlined />}
          onClick={() => setSelectedMainSection(section)}
        >
          {isEditModeEnabled ? (
            <>
              <span>{section.title}</span>
              <Button 
                icon={<EditOutlined />} 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditStart(section.id, section.title, 'mainSection');
                }}
                style={{ marginLeft: '8px' }}
              />
              <Popconfirm
                title="Are you sure you want to delete this main section?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleDelete('mainSection', section.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button 
                  icon={<DeleteOutlined />} 
                  size="small" 
                  danger
                  onClick={(e) => e.stopPropagation()}
                  style={{ marginLeft: '8px' }}
                />
              </Popconfirm>
            </>
          ) : (
            section.title
          )}
        </Menu.Item>
      ))}
      {isEditModeEnabled && (
        <Menu.Item 
          key="add-main-section" 
          icon={<PlusOutlined />}
          onClick={() => {
            setModalType('mainSection');
            setModalVisible(true);
          }}
        >
          Add Main Section
        </Menu.Item>
      )}
    </Menu>
  );

  const renderSubsections = () => {
    if (!selectedMainSection) return null;

    return (
      <Menu mode="inline" selectedKeys={[selectedSubsection?.id]} defaultOpenKeys={Object.keys(selectedMainSection.subsections || {})}>
        {selectedMainSection.subsections && Object.values(selectedMainSection.subsections).map((subsection) => (
          <SubMenu 
            key={subsection.id} 
            icon={<FolderOutlined />} 
            title={
              isEditModeEnabled ? (
                <>
                  <span>{subsection.title}</span>
                  <Button 
                    icon={<EditOutlined />} 
                    size="small" 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditStart(subsection.id, subsection.title, 'subsection');
                    }}
                    style={{ marginLeft: '8px' }}
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this subsection?"
                    onConfirm={(e) => {
                      e.stopPropagation();
                      handleDelete('subsection', subsection.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button 
                      icon={<DeleteOutlined />} 
                      size="small" 
                      danger
                      onClick={(e) => e.stopPropagation()}
                      style={{ marginLeft: '8px' }}
                    />
                  </Popconfirm>
                </>
              ) : (
                subsection.title
              )
            }
          >
            {subsection.topics && subsection.topics.map((topic) => (
              <Menu.Item 
                key={topic.id} 
                icon={<FileOutlined />}
                onClick={() => handleTopicSelect(topic.id)}
                style={{ paddingLeft: '24px' }}
              >
                <span style={{ borderLeft: '1px solid #d9d9d9', paddingLeft: '12px' }}>
                  {isEditModeEnabled ? (
                    <>
                      <span>{topic.title}</span>
                      <Button 
                        icon={<EditOutlined />} 
                        size="small" 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditStart(topic.id, topic.title, 'topic');
                        }}
                        style={{ marginLeft: '8px' }}
                      />
                      <Popconfirm
                        title="Are you sure you want to delete this topic?"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          handleDelete('topic', topic.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button 
                          icon={<DeleteOutlined />} 
                          size="small" 
                          danger
                          onClick={(e) => e.stopPropagation()}
                          style={{ marginLeft: '8px' }}
                        />
                      </Popconfirm>
                    </>
                  ) : (
                    topic.title
                  )}
                </span>
              </Menu.Item>
            ))}
            {isEditModeEnabled && (
              <Menu.Item 
                key={`add-topic-${subsection.id}`} 
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalType('topic');
                  setParentId(subsection.id);
                  setModalVisible(true);
                }}
              >
                Add Topic
              </Menu.Item>
            )}
          </SubMenu>
        ))}
        {isEditModeEnabled && (
          <Menu.Item 
            key={`add-subsection-${selectedMainSection.id}`} 
            icon={<PlusOutlined />}
            onClick={() => {
              setModalType('subsection');
              setParentId(selectedMainSection.id);
              setModalVisible(true);
            }}
          >
            Add Subsection
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px' }}>
      <div className="logo" style={{ color: 'white', fontSize: '20px', fontWeight: 'bold' }}>Wiki</div>
        <Search
          placeholder="Search wiki..."
          onSearch={handleSearch}
          style={{ width: 300 }}
        />
        <Button 
          type={isEditModeEnabled ? "primary" : "default"} 
          onClick={() => setIsEditModeEnabled(!isEditModeEnabled)}
        >
          {isEditModeEnabled ? "Disable Edit Mode" : "Enable Edit Mode"}
        </Button>
        <Avatar src="https://example.com/avatar.jpg" />
      </Header>
      <Layout>
        <Sider width={250} className="site-layout-background" style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
          {renderMainSections()}
        </Sider>
        <Sider width={250} className="site-layout-background" style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}>
          {renderSubsections()}
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              height: 'calc(100vh - 112px)',
              overflow: 'auto',
            }}
          >
            {contentLoading ? (
              <Spin size="large" />
            ) : selectedTopic ? (
              <Card
                title={selectedTopic.title}
                extra={
                  isEditModeEnabled && (
                    <Button
                      type="primary"
                      icon={editMode ? <SaveOutlined /> : <EditOutlined />}
                      onClick={toggleEditMode}
                    >
                      {editMode ? 'Save' : 'Edit'}
                    </Button>
                  )
                }
              >
                {editMode ? (
                  <TextArea
                    rows={20}
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                  />
                ) : (
                  <div className="markdown-content">
                    <ReactMarkdown>{selectedTopic.content}</ReactMarkdown>
                  </div>
                )}
              </Card>
            ) : searchResults.length > 0 ? (
              <Card title="Search Results">
                {searchResults.map(result => (
                  <div key={result.id} onClick={() => handleTopicSelect(result.id)} style={{ cursor: 'pointer', marginBottom: '10px' }}>
                    <Title level={5}>{result.title}</Title>
                    <Paragraph>{result.excerpt}</Paragraph>
                  </div>
                ))}
              </Card>
            ) : (
              <Text>Select a topic from the menu or search for content</Text>
            )}
          </Content>
        </Layout>
      </Layout>

      <Modal
        title={`${editingItemId ? 'Edit' : 'Create'} ${modalType}`}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingItemId(null);
          setEditingItemTitle('');
          setParentId(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={editingItemId ? handleUpdate : handleCreate}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: 'Please input the title!' }]}
          >
            <Input />
          </Form.Item>
          {modalType === 'topic' && (
            <Form.Item
              name="content"
              label="Content (Markdown supported)"
              rules={[{ required: true, message: 'Please input the content!' }]}
            >
              <TextArea rows={10} />
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingItemId ? 'Update' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Help"
        visible={helpModalVisible}
        onCancel={() => setHelpModalVisible(false)}
        footer={null}
      >
        <Typography>
          <Title level={4}>How to use the Wiki</Title>
          <Paragraph>
            1. Navigate through main sections in the left column.
          </Paragraph>
          <Paragraph>
            2. Select a main section to view its subsections and topics in the middle column.
          </Paragraph>
          <Paragraph>
            3. Click on a topic to view its content in the main area.
          </Paragraph>
          <Paragraph>
            4. Use the search bar at the top to find specific topics or content.
          </Paragraph>
          <Paragraph>
            5. To edit a topic, enable edit mode and click the 'Edit' button when viewing a topic.
          </Paragraph>
          <Paragraph>
            6. To add new sections, subsections, or topics, enable edit mode and use the '+' buttons in the respective menus.
          </Paragraph>
          <Paragraph>
            7. To edit or delete existing sections, subsections, or topics, enable edit mode and use the edit and delete buttons next to each item.
          </Paragraph>
        </Typography>
      </Modal>
    </Layout>
  );
};

export default Wiki;

// Styles
const styles = `
  .wiki-layout {
    min-height: 100vh;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: #001529;
  }

  .logo {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .site-layout-background {
    background: #fff;
  }

  .ant-layout-sider {
    overflow-y: auto;
    height: calc(100vh - 64px);
  }

  .ant-menu-inline {
    border-right: none;
  }

  .ant-card {
    margin-bottom: 24px;
  }

  .ant-card-head-title {
    font-size: 18px;
    font-weight: 600;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }

  .ant-list-item-meta-description {
    font-size: 12px;
  }

  .markdown-content {
    font-size: 16px;
    line-height: 1.6;
  }

  .markdown-content h1 {
    font-size: 2em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 0.3em;
  }

  .markdown-content h2 {
    font-size: 1.5em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 0.3em;
  }

  .markdown-content h3 {
    font-size: 1.25em;
  }

  .markdown-content h4 {
    font-size: 1em;
  }

  .markdown-content h5 {
    font-size: 0.875em;
  }

  .markdown-content h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3,
  .markdown-content h4,
  .markdown-content h5,
  .markdown-content h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
  }

  .markdown-content p {
    margin-bottom: 16px;
  }

  .markdown-content ul,
  .markdown-content ol {
    padding-left: 24px;
    margin-bottom: 16px;
  }

  .markdown-content code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }

  .markdown-content pre {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 4px;
    overflow-x: auto;
  }

  .markdown-content pre code {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  .markdown-content blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin-bottom: 16px;
  }

  .markdown-content table {
    border-collapse: collapse;
    margin-bottom: 16px;
    width: 100%;
  }

  .markdown-content table th,
  .markdown-content table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  .markdown-content table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
`;

// Add this line at the end of the file to inject the styles
const styleElement = document.createElement('style');
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);