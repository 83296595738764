import React, { useState, useEffect, useCallback, useRef, useReducer } from 'react';
import { Modal, Collapse, Form, Input, DatePicker, Select, Button, message, Space, Typography } from 'antd';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { debounce } from 'lodash';
import useSound from 'use-sound';
import alert from './alert.mp3';
import urgentAlert from './Bubble.mp3';
import { getUpcomingReminderNotifications, getReminder, updateReminder, getAllUsers, deleteReminder } from '../apiService';
import styled, { keyframes } from 'styled-components';

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;

const glowAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GlowWrapper = styled.div`
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(45deg, purple, yellow, red, blue);
    background-size: 400% 400%;
    z-index: -1;
    filter: blur(5px);
    animation: ${glowAnimation} 15s ease infinite;
    border-radius: 15px;
    opacity: 0.7;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: #1f1f1f;
    color: #ffffff;
    border-radius: 12px;
  }
  .ant-modal-header {
    background-color: #1f1f1f;
    border-bottom: 1px solid #333;
  }
  .ant-modal-title {
    color: #ffffff;
  }
  .ant-modal-close-x {
    color: #ffffff;
  }
  .ant-modal-footer {
    border-top: 1px solid #333;
  }
`;

const IconWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
  color: #1890ff;
`;

const HeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const CollapsedContent = styled.div`
  color: #ffffff;
  .ant-typography {
    color: #ffffff !important;
  }
  .ant-typography-secondary {
    color: #a0a0a0 !important;
  }
`;

const NotificationBox = styled(GlowWrapper)`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #1890ff;
  color: white;
  padding: 10px 20px;
  border-radius: 12px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const initialState = {
  reminders: [],
  visible: false,
  notificationVisible: false,
  lastFetchTime: 0,
  error: null,
  urgentReminders: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_REMINDERS':
      return { ...state, reminders: action.payload, lastFetchTime: Date.now() };
    case 'SET_VISIBLE':
      return { ...state, visible: action.payload };
    case 'SET_NOTIFICATION_VISIBLE':
      return { ...state, notificationVisible: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_URGENT_REMINDERS':
      return { ...state, urgentReminders: action.payload };
    default:
      return state;
  }
}

const ReminderPopup = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const lastFetchTimeRef = useRef(0);
  const [play] = useSound(alert);
  const [playUrgent] = useSound(urgentAlert);

  const parseDateTime = (dateTimeString) => {
    return moment(dateTimeString).tz('Asia/Kolkata');
  };

  const formatDateTimeForDisplay = (dateTime) => {
    return moment(dateTime).format('D-MMM-YYYY hh:mm A');
  };

  const formatDateTimeForAPI = (dateTime) => {
    return moment(dateTime).format('YYYY-MM-DDTHH:mm:ssZ');
  };

  const getNotificationTime = (notificationType, dueDateTime) => {
    const due = parseDateTime(dueDateTime);
    switch (notificationType) {
      case '1_hour':
        return due.subtract(1, 'hour').format('D-MMM-YYYY hh:mm A');
      case '10_minutes':
        return due.subtract(10, 'minutes').format('D-MMM-YYYY hh:mm A');
      case 'due':
        return due.format('D-MMM-YYYY hh:mm A');
      default:
        return 'Unknown';
    }
  };

  const fetchReminders = useCallback(
    debounce(async (force = false) => {
      const now = Date.now();
      if (force || now - lastFetchTimeRef.current > 290000) {
        try {
          const response = await getUpcomingReminderNotifications();
          const formattedReminders = await Promise.all(
            response.map(async (reminder) => {
              const fullReminder = await getReminder(reminder.id);
              return {
                ...fullReminder,
                ...reminder,
                due_datetime: formatDateTimeForDisplay(reminder.due_datetime),
                notification_time: getNotificationTime(reminder.notification_type, reminder.due_datetime),
              };
            })
          );
          dispatch({ type: 'SET_REMINDERS', payload: formattedReminders });
          lastFetchTimeRef.current = now;

          const urgentReminders = formattedReminders.filter(reminder => 
            moment(reminder.due_datetime, 'D-MMM-YYYY hh:mm A').isBefore(moment().add(1, 'hour'))
          );
          dispatch({ type: 'SET_URGENT_REMINDERS', payload: urgentReminders });

          if (formattedReminders.length > 0 && !state.visible && !state.notificationVisible) {
            dispatch({ type: 'SET_NOTIFICATION_VISIBLE', payload: true });
            play();
          } else if (formattedReminders.length === 0) {
            dispatch({ type: 'SET_NOTIFICATION_VISIBLE', payload: false });
          }
        } catch (error) {
          console.error('Error fetching upcoming reminders:', error);
          dispatch({ type: 'SET_ERROR', payload: 'Failed to load upcoming reminders' });
        }
      }
    }, 300),
    [state.visible, state.notificationVisible, play]
  );

  const fetchUsers = async () => {
    try {
      const response = await getAllUsers();
      setUsers(response.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to load users');
    }
  };

  useEffect(() => {
    fetchReminders(true);
    fetchUsers();
    const interval = setInterval(() => fetchReminders(), 120000);
    return () => clearInterval(interval);
  }, [fetchReminders]);

  useEffect(() => {
    const urgentInterval = setInterval(() => {
      if (state.urgentReminders.length > 0) {
        playUrgent();
      }
    }, 300000); // Play urgent alert every 5 minutes

    return () => clearInterval(urgentInterval);
  }, [state.urgentReminders, playUrgent]);

  const handleUpdate = async (values) => {
    try {
      const reminderData = {
        ...values,
        due_datetime: formatDateTimeForAPI(values.due_datetime)
      };
      await updateReminder(values.id, reminderData);
      message.success('Reminder updated successfully');
      fetchReminders(true);
    } catch (error) {
      console.error('Error updating reminder:', error);
      message.error('Failed to update reminder');
    }
  };

  const handleComplete = async (reminderId) => {
    try {
      const reminderDetails = await getReminder(reminderId);
      if (reminderDetails.status === 'completed') {
        message.info('Reminder is already completed');
        return;
      }

      const updateData = {
        ...reminderDetails,
        status: 'completed',
        due_datetime: formatDateTimeForAPI(parseDateTime(reminderDetails.due_datetime))
      };

      await updateReminder(reminderId, updateData);
      message.success('Reminder marked as complete');
      fetchReminders(true);
    } catch (error) {
      console.error('Error completing reminder:', error);
      message.error('Failed to complete reminder');
    }
  };

  const handleDelete = async (reminderId) => {
    try {
      await deleteReminder(reminderId);
      message.success('Reminder deleted successfully');
      fetchReminders(true);
    } catch (error) {
      console.error('Error deleting reminder:', error);
      message.error('Failed to delete reminder');
    }
  };

  const handleClosePopup = () => {
    dispatch({ type: 'SET_VISIBLE', payload: false });
    if (state.reminders.length > 0) {
      dispatch({ type: 'SET_NOTIFICATION_VISIBLE', payload: true });
    }
  };

  const handlePanelChange = useCallback((key) => {
    if (key) {
      const reminder = state.reminders.find(r => r.id.toString() === key);
      if (reminder) {
        getReminder(reminder.id).then(updatedReminder => {
          form.setFieldsValue({
            ...updatedReminder,
            due_datetime: parseDateTime(updatedReminder.due_datetime)
          });
        });
      }
    }
  }, [form, state.reminders]);

  const renderReminderContent = (reminder) => (
    <div>
      <Text strong style={{ color: '#ffffff' }}>{reminder.topic}:</Text> <Text style={{ color: '#ffffff' }}>{reminder.message}</Text>
      <Form
        form={form}
        initialValues={{
          ...reminder,
          due_datetime: parseDateTime(reminder.due_datetime),
        }}
        onFinish={handleUpdate}
        layout="vertical"
      >
        <Form.Item name="id" hidden><Input /></Form.Item>
        <Form.Item name="lead_id" label="Lead ID">
          <Input disabled />
        </Form.Item>
        <Form.Item name="topic" label="Topic" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="due_datetime" label="Due Date and Time" rules={[{ required: true }]}>
          <DatePicker
            showTime
            format="D-MMM-YYYY hh:mm A"
          />
        </Form.Item>
        <Form.Item name="message" label="Message" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="assigned_to" label="Assigned To" rules={[{ required: true }]}>
          <Select>
            {users.map(user => (
              <Option key={user.id} value={user.id}>{user.display_name || user.username}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
            <Button onClick={() => handleComplete(reminder.id)}>
              Mark as Complete
            </Button>
            <Button danger onClick={() => handleDelete(reminder.id)}>
              Delete
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      <GlowWrapper>
        <StyledModal
          visible={state.visible}
          onCancel={handleClosePopup}
          footer={[
            <Button key="close" onClick={handleClosePopup}>
              Close
            </Button>
          ]}
          width={600}
          bodyStyle={{ maxHeight: '70vh', overflowY: 'auto' }}
          style={{ top: 20 }}
        >
          <IconWrapper>
            <BellOutlined />
          </IconWrapper>
          <HeaderWrapper>
            <Text strong style={{ fontSize: '18px', color: '#ffffff' }}>Due Reminders</Text>
          </HeaderWrapper>
          <Collapse accordion onChange={handlePanelChange}>
            {state.reminders.map(reminder => (
              <Panel 
                header={
                  <CollapsedContent>
                    <Text strong>{reminder.topic}</Text>
                    <br />
                    <Text type="secondary">Due: {reminder.due_datetime}</Text>
                    <br />
                    <Text type="secondary">Notification: {reminder.notification_time}</Text>
                  </CollapsedContent>
                } 
                key={reminder.id}
              >
                {renderReminderContent(reminder)}
              </Panel>
            ))}
          </Collapse>
        </StyledModal>
      </GlowWrapper>

      {state.notificationVisible && !state.visible && (
        <NotificationBox onClick={() => dispatch({ type: 'SET_VISIBLE', payload: true })}>
          <Space>
            <BellOutlined />
            <span>Due Reminders</span>
            <CloseOutlined onClick={(e) => {
              e.stopPropagation();
              dispatch({ type: 'SET_NOTIFICATION_VISIBLE', payload: false });
            }} />
          </Space>
        </NotificationBox>
      )}
    </>
  );
};

export default ReminderPopup;