import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, TimePicker, Button, InputNumber } from 'antd';
import moment from 'moment';

const { TextArea } = Input;

const WebhookForm = ({ leadName, phone, email, coordinatorName, onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const formattedValues = {
      ...values,
      slot_1: values.slot_1 ? values.slot_1.format('HH:mm') : null,
      slot_2: values.slot_2 ? values.slot_2.format('HH:mm') : null,
      slot_3: values.slot_3 ? values.slot_3.format('HH:mm') : null,
    };
    onSubmit(formattedValues);
  };

  return (
    <Form 
      form={form} 
      onFinish={handleSubmit} 
      initialValues={{ 
        name: leadName, 
        phone, 
        email, 
        coordinator_name: coordinatorName 
      }}
    >
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="course" label="Course" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="slot_1" label="Slot 1">
        <TimePicker use12Hours format="h:mm A" />
      </Form.Item>
      <Form.Item name="slot_2" label="Slot 2">
        <TimePicker use12Hours format="h:mm A" />
      </Form.Item>
      <Form.Item name="slot_3" label="Slot 3">
        <TimePicker use12Hours format="h:mm A" />
      </Form.Item>
      <Form.Item name="duration" label="Duration (minutes)" rules={[{ required: true }]}>
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item name="remarks" label="Remarks">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="coordinator_name" label="Coordinator Name">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Send for allotment
        </Button>
      </Form.Item>
    </Form>
  );
};

WebhookForm.propTypes = {
  leadName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string,
  coordinatorName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default WebhookForm;